import React from 'react';
import './EditRolePopup.css';
import close from "../../assets/icons/close.svg";

const EditRolePopup = ({ cancelEdit, updateRole, loading, role, search, handleChange, handleSearchChange, addSkill, searchSkill, userSkills, removeSkills, editType }) => {
    let filteredSkills = userSkills.filter((data) => data.skillId !== null);
    filteredSkills = filteredSkills.filter((v, i, a) => a.findIndex(v2 => (v2.skillId.skills === v.skillId.skills)) === i);

    return (
        <div className='edit-role-popup'>
            <div className='edit-container'>
                <div className="heading">
                    <div className="left-side">
                        <div className="sidebar"></div>
                        <span>{editType === "assign" ? "Assign" : "Edit Role"}</span>
                    </div>

                    <div className='button-container'>
                        <button onClick={updateRole}>{loading ? "Loading..." : "Update"}</button>
                        <img src={close} alt="close" onClick={cancelEdit} />
                    </div>
                </div>


                <div className='form'>
                    <div className='input-container'>
                        <label>Selected Role</label>
                        <input value={role} type="text" placeholder="Role name" onChange={editType === "assign" ? null : handleChange("jobrole")} />
                    </div>

                    <div className='input-container' style={{ marginTop: 15 }}>
                        <label>Assign Skills</label>
                        <input value={search} style={{ width: '100%' }} type="text" placeholder="Search Skills" onChange={handleSearchChange} />
                    </div>

                    {/* Skill container toggle as per skills to show */}
                    <React.Fragment>
                        {
                            search !== "" && searchSkill.length !== 0 ? <div className="skill-container">
                                {
                                    searchSkill.map((data) => {
                                        return <span key={data._id} onClick={() => addSkill(data)}>{data.skills}</span>;
                                    })}
                            </div> : null
                        }
                    </React.Fragment>

                    <React.Fragment>
                        {
                            filteredSkills.length !== 0 ? <div className='tag-container'>
                                {
                                    filteredSkills.map((data) => {
                                        return <div className='tag' key={data.skillId.skills}>
                                            {data.skillId.skills}
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => removeSkills(data)}>
                                                <circle cx="10" cy="10" r="10" fill="#E2E8EB" />
                                                <path d="M13 7L7 13" stroke="#FF6812" stroke-linecap="round" />
                                                <path d="M13 13L7.00019 7.00019" stroke="#FF6812" stroke-linecap="round" />
                                            </svg>
                                        </div>;
                                    })}
                            </div> : null
                        }
                    </React.Fragment>
                </div>
            </div>
        </div>
    );
};

export default EditRolePopup;