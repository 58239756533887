import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#E4E4E4',
    },
    heading: {
        display: "h1",
        marginTop: "20px",
        marginBottom: "20px",
        padding: "10px"
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        margin: "20px"
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol: {
        width: "25%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 10
    }
});


const MyDocument = ({ data }) => ( // 
    <Document>
        <Page style={styles.body}>
            <View style={styles.heading}>
                <Text style={styles.heading}>Invoice</Text>
            </View>
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>Invoice Number</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>Discount</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>Final Amount</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>Verified</Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.invoiceNumber == null ? "-" : data.invoiceNumber}</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.discount == null ? "-" : data.discount} %</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.finalAmount == null ? "-" : data.finalAmount}</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>Yes</Text>
                    </View>
                </View>
            </View>
        </Page>
    </Document>
);

export default MyDocument;