import React, { useState, useEffect } from "react";
import "./RightAddDifficultyLevel.css";
import axios from "axios";
import { toast } from "react-toastify";
import save from "../../assets/icons/save.svg";
import cancel from "../../assets/icons/cancel.svg";

const RightAddDifficultyLevel = () => {
  const [loading, setLoading] = useState(false);
  const [editActive, setEditActive] = useState(false);
  const [levelId, setlevelId] = useState("");
  const [levelLoading, setlevelLoading] = useState(false);
  const [level, setLevel] = useState([]);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [levelDetails, setLevelDetails] = useState({
    level: "",
    experience: "",
    programmingTime: "",
    mcqTime: "",
    programmingScore: "",
    mcqScore: "",
  });
  const token = localStorage.getItem("token");

  useEffect(() => {
    getLevels();
  }, []);

  const createDifficultyLevel = async () => {
    try {
      setLoading(true);
      const body = {
        levelDetails,
      };
      const response = await axios.post(
        "https://api.theeliteqa.com/api/difficultLevel/createDifficultyLevel",
        body.levelDetails,
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.status === 201) {
        toast.success("Difficulty level created successfully.");
        setLevelDetails({
          level: "",
          experience: "",
          programmingTime: "",
          mcqTime: "",
          programmingScore: "",
          mcqScore: "",
        });
        setLoading(false);
        getLevels();
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to create difficulty level.");
      setLoading(false);
    }
  };

  const updateDifficultyLevel = async () => {
    try {
      setLoading(true);
      const body = {
        levelDetails,
      };
      const response = await axios.put(
        `https://api.theeliteqa.com/api/difficultLevel/updateDifficultyLevel/${levelId}`,
        body.levelDetails,
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Difficulty level updated successfully.");
        setLevelDetails({
          level: "",
          experience: "",
          programmingTime: "",
          mcqTime: "",
          programmingScore: "",
          mcqScore: "",
        });
        setLoading(false);
        getLevels();
      }
    } catch (error) {
      toast.error("Failed to update experience.");
    }
  };

  const getPaginatedData = () => {
    const startIndex = currentPage * 10 - 10;
    const endIndex = startIndex + 10;
    return level.slice(startIndex, endIndex);
  };

  const getLevels = async () => {
    try {
      setlevelLoading(true);
      const response = await axios(
        "https://api.theeliteqa.com/api/difficultLevel/getDifficultyLevel",
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.status === 200) {
        setLevel(response.data.data);
        setTotalPage(Math.floor(response.data.data.length / 10) + 1);
        setLoading(false);
        setPages(level.length / 10);
        setlevelLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function goToNextPage() {
    // console.log(pageNum, "   ", totalPage)
    if (pageNum >= totalPage) {
      return toast.error("No more Pages");
    } else {
      setCurrentPage((page) => page + 1);
      setPageNum(pageNum + 1);
    }
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
    setPageNum((pageNum) => pageNum - 1);
  }

  const deleteDifficultyLevel = async (id) => {
    try {
      await axios.delete(
        `https://api.theeliteqa.com/api/difficultLevel//deleteDifficultyLevel/${id}`,
        {
          headers: {
            token: token,
          },
        }
      );
      toast.success("Difficulty level deleted successfully.");
      let updatedData = level.filter((data) => data._id !== id);
      setLevel(updatedData);
    } catch (error) {
      toast.error("Failed to delete difficulty level.");
    }
  };

  const cancelEditing = () => {
    setLevelDetails({
      level: "",
      experience: "",
      programmingTime: "",
      mcqTime: "",
    });
    setlevelId("");
    setEditActive(false);
  };

  const handleEdit = (data) => {
    setEditActive(true);
    setlevelId(data._id);
    setLevelDetails({
      level: data.level,
      experience: data.experience,
      programmingTime: data.programmingTime,
      mcqTime: data.mcqTime,
      programmingScore: data.programmingScore,
      mcqScore: data.mcqScore,
    });
  };

  return (
    <div className="right-level">
      <div className="right-level-container">
        <div className="heading">
          <div className="title">
            <div className="sidebar"></div>
            <span>Add Difficulty Level Form</span>
          </div>
          <div className="buttons-container">
            {editActive ? (
              <button className="cancel" onClick={cancelEditing}>
                <img src={cancel} alt="save" />
                Cancel
              </button>
            ) : null}
            <button
              className="save"
              onClick={
                editActive ? updateDifficultyLevel : createDifficultyLevel
              }
              title={editActive ? "Update" : "Save"}
            >
              <img src={save} alt="save" />
              {loading ? (
                <i class="fa fa-circle-o-notch fa-spin"></i>
              ) : editActive ? (
                "Update"
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>

        <div className="form-container">
          <div className="row1">
            <div className="input-container">
              <label>Level</label>
              <input
                type="text"
                placeholder="Level"
                onChange={(e) =>
                  setLevelDetails({ ...levelDetails, level: e.target.value })
                }
                value={levelDetails.level}
              />
            </div>
            <div className="input-container">
              <label>Experience</label>
              <input
                type="text"
                placeholder="Experience"
                onChange={(e) =>
                  setLevelDetails({
                    ...levelDetails,
                    experience: e.target.value,
                  })
                }
                value={levelDetails.experience}
              />
            </div>
          </div>

          <div className="row1" style={{ marginTop: 24 }}>
            <div className="input-container">
              <label>Programming Time</label>
              <input
                type="number"
                placeholder="Programming Time"
                onChange={(e) =>
                  setLevelDetails({
                    ...levelDetails,
                    programmingTime: e.target.value,
                  })
                }
                value={levelDetails.programmingTime}
              />
            </div>
            <div className="input-container">
              <label>Mcq Time</label>
              <input
                type="number"
                placeholder="Mcq Time"
                onChange={(e) =>
                  setLevelDetails({ ...levelDetails, mcqTime: e.target.value })
                }
                value={levelDetails.mcqTime}
              />
            </div>
          </div>
          <div className="row1" style={{ marginTop: 24 }}>
            <div className="input-container">
              <label>Programming Score</label>
              <input
                type="number"
                placeholder="Programming Score"
                onChange={(e) =>
                  setLevelDetails({
                    ...levelDetails,
                    programmingScore: e.target.value,
                  })
                }
                value={levelDetails.programmingScore}
              />
            </div>
            <div className="input-container">
              <label>Mcq Score</label>
              <input
                type="number"
                placeholder="Mcq Time"
                onChange={(e) =>
                  setLevelDetails({ ...levelDetails, mcqScore: e.target.value })
                }
                value={levelDetails.mcqScore}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="list-topic">
        <div className="heading">
          <div className="title-group">
            <div className="sidebar"></div>
            <span>Added Difficulty Level</span>
          </div>
          <div className="pagination">
            {/* previous button */}
            <button
              onClick={goToPreviousPage}
              // className={`prev ${currentPage === 1 ? 'disabled' : ''}`}
              disabled={pageNum <= 1 ? "true" : ""}
              title="Previous"
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.41421 6L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289L5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893C7.09763 0.683418 7.09763 1.31658 6.70711 1.70711L2.41421 6Z"
                  fill="white"
                />
              </svg>
            </button>

            <span> {pageNum + "/" + totalPage}</span>

            {/* next button */}
            <button
              onClick={goToNextPage}
              className={`next ${currentPage === pages ? "disabled" : ""}`}
              disabled={pageNum >= totalPage ? "true" : ""}
              title="Next"
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
        {levelLoading ? (
          <div className="loading-container">
            <i
              class="fa fa-circle-o-notch fa-spin fa-5x"
              style={{
                color: "#FF6813",
              }}
            ></i>
          </div>
        ) : (
          <div className="topic-container">
            <table>
              <tr>
                <th>Sr.No</th>
                <th>Level</th>
                <th>Experience</th>
                <th>Programming Time</th>
                <th>Mcq Time</th>
                <th>Programming Score</th>
                <th>Mcq Score</th>
                <th>Options</th>
              </tr>

              {getPaginatedData()?.map((data, index) => {
                return (
                  <tr>
                    <td>{index + 1 + 10 * (pageNum - 1)}</td>
                    <td>{data.level}</td>
                    <td>{data.experience}</td>
                    <td>{data.programmingTime}</td>
                    <td>{data.mcqTime}</td>
                    <td>{data?.programmingScore || "NA"}</td>
                    <td>{data?.mcqScore || "NA"}</td>
                    <td>
                      <div className="options">
                        {/* Edit Icon */}
                        <div
                          className="editIconCircle"
                          title="Edit"
                          onClick={() => {
                            handleEdit(data);
                          }}
                        >
                          <svg
                            className="editIcon"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_1351_1043)">
                              <path
                                d="M9.16666 3.33334H3.33332C2.8913 3.33334 2.46737 3.50893 2.15481 3.82149C1.84225 4.13405 1.66666 4.55798 1.66666 5V16.6667C1.66666 17.1087 1.84225 17.5326 2.15481 17.8452C2.46737 18.1577 2.8913 18.3333 3.33332 18.3333H15C15.442 18.3333 15.8659 18.1577 16.1785 17.8452C16.4911 17.5326 16.6667 17.1087 16.6667 16.6667V10.8333"
                                stroke="blue"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M15.4167 2.08333C15.7482 1.75181 16.1978 1.56557 16.6667 1.56557C17.1355 1.56557 17.5851 1.75181 17.9167 2.08333C18.2482 2.41485 18.4344 2.86449 18.4344 3.33333C18.4344 3.80217 18.2482 4.25181 17.9167 4.58333L9.99999 12.5L6.66666 13.3333L7.49999 10L15.4167 2.08333Z"
                                stroke="blue"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1351_1043">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>

                        {/* Delete Icon */}
                        <div
                          className="deleteIconCircle"
                          title="Delete"
                          onClick={() => {
                            deleteDifficultyLevel(data._id);
                          }}
                        >
                          <svg
                            className="deleteIcon"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.5 5H4.16667H17.5"
                              stroke="red"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M15.8333 5V16.6667C15.8333 17.1087 15.6577 17.5326 15.3451 17.8452C15.0326 18.1577 14.6087 18.3333 14.1666 18.3333H5.83329C5.39127 18.3333 4.96734 18.1577 4.65478 17.8452C4.34222 17.5326 4.16663 17.1087 4.16663 16.6667V5M6.66663 5V3.33333C6.66663 2.89131 6.84222 2.46738 7.15478 2.15482C7.46734 1.84226 7.89127 1.66667 8.33329 1.66667H11.6666C12.1087 1.66667 12.5326 1.84226 12.8451 2.15482C13.1577 2.46738 13.3333 2.89131 13.3333 3.33333V5"
                              stroke="red"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.33337 9.16667V14.1667"
                              stroke="red"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.6666 9.16667V14.1667"
                              stroke="red"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default RightAddDifficultyLevel;
