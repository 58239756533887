import React from 'react'
import './ViewSkillPopup.css'
import close from "../../assets/icons/close.svg";

const ViewSkillPopup = ({ closeView, viewData }) => {
    const filteredTopic = viewData.topics.filter((data) => data.topicId !== null);
    return (
        <div className='view-skill-popup'>
            <div className='view-container'>
                <div className="heading">
                    <div className='left-side'>
                        <div className="sidebar"></div>
                        <span>View Skills</span>
                    </div>
                    <img src={close} alt="close" onClick={closeView} />
                </div>

                <div className='form-container'>
                    <div>
                        <label>Skill</label>
                        <div className='role-container'>{viewData.skills}</div>
                    </div>

                    <div style={{ marginTop: 20 }}>
                        <label>Topics</label>
                        {
                            filteredTopic.length !== 0 ? <div className="tag-container">
                                {
                                    filteredTopic.map((data) => {
                                        return <div className='tag' key={data.topicId.topic}>
                                            {data.topicId.topic}
                                        </div>
                                    })
                                }
                            </div> : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewSkillPopup