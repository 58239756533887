import React, { useState, useEffect, useContext, useMemo } from "react";
import "./RightManageClientUser.css";
import axios from "axios";
import { toast } from "react-toastify";
import DataContext from "../../store/DataProvider";
import addmedia from "../../assets/icons/addmedia.svg";
import save from "../../assets/icons/save.svg";
import cancel from "../../assets/icons/cancel.svg";
import { validateTextInput } from "../../functions/validateTextInput";
import DataListInput from "react-datalist-input";
import { validateUsername } from "../../functions/validateUsername";
import close from "../../assets/icons/contactclose.svg";

function RightManageClientUser() {
  const context = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [department, setDepartment] = useState([]);
  const [passwordView, setpasswordView] = useState(false);
  const [clientUser, setClientUser] = useState({
    client: "",
    fullName: "",
    gender: "",
    email: "",
    departmentId: "",
    userName: "",
    password: "",
    profileUrl: "",
  });
  const token = localStorage.getItem("token");

  const getClients = async () => {
    //api num 38
    try {
      const response = await axios("https://api.theeliteqa.com/api/client/", {
        headers: {
          token: token,
        },
      });
      setClients(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDepartments = async () => {
    //api num 4
    try {
      const response = await axios(
        "https://api.theeliteqa.com/api/department/",
        {
          headers: {
            token: token,
          },
        }
      );
      setDepartment(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getClients();
    getDepartments();

    if (context.editActive === "client-user-edit") {
      setClientUser({
        client: context.clientUserData.client?._id,
        fullName: context.clientUserData.fullName,
        gender: context.clientUserData.gender,
        email: context.clientUserData.email,
        departmentId: context.clientUserData.departmentId?._id,
        userName: context.clientUserData.userName,
        password: context.clientUserData.password,
        profileUrl: context.clientUserData.profileUrl,
      });
    }
  }, [context.changeEditActive === "manageclientuser-edit"]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (name) => (event) => {
    const value = event.target.value;
    setClientUser({ ...clientUser, [name]: value });
  };

  const createClientUser = async () => {
    try {
      setLoading(true);
      const body = {
        clientUser,
      };
      // console.log("create body", body.clientUser)
      const response = await axios.post(
        "https://api.theeliteqa.com/api/eqa/create",
        body.clientUser,
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.data.message === "User already exits...") {
        setLoading(false);
        return toast.error("User already exits.");
      } else {
        toast.success("Client user created successfully.");
        setClientUser({
          client: "",
          fullName: "",
          gender: "",
          email: "",
          departmentId: "",
          userName: "",
          password: "",
          profileUrl: "",
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Failed to create client user.");
    }
  };

  const updateClient = async () => {
    try {
      setLoading(true);
      const body = {
        clientUser,
      };
      await axios.put(
        `https://api.theeliteqa.com/api/eqa/update/${context.clientUserId}`,
        body.clientUser,
        {
          headers: {
            token: token,
          },
        }
      );
      toast.success("Client User updated successfully.");
      context.changeEditActive("");
      setClientUser({
        client: "",
        fullName: "",
        gender: "",
        email: "",
        departmentId: "",
        userName: "",
        password: "",
        profileUrl: "",
      });
      setLoading(false);
      context.changeActive("viewclientuser");
    } catch (error) {
      setLoading(false);
      toast.error("Failed to update client user.");
    }
  };

  const isEmail = (val) => {
    let regEmail = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}"); //eslint-disable-line
    if (!regEmail.test(val)) {
      return "Invalid Email";
    }
  };

  const passwordStrength = (val) => {
    let strongPassword = new RegExp(
      "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
    );
    let mediumPassword = new RegExp(
      "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
    );

    if (strongPassword.test(val)) {
      return "strong";
    } else if (mediumPassword.test(val)) {
      return "Medium";
    } else {
      return "weak";
    }
  };

  const validateData = () => {
    const isValidEmail = isEmail(clientUser.email);
    const isSpecialCharFullName = validateInputFields(clientUser.fullName);
    const isSpecialCharUsername = validateInputFields(clientUser.userName);

    if (isValidEmail === "Invalid Email") {
      return toast.error(isValidEmail);
    } else if (clientUser.password.length < 8) {
      return toast.error("Password must be atleast 8 char.");
    } else if (isSpecialCharFullName) {
      return toast.error("Special char and numbers not allowed in full name.");
    } else if (isSpecialCharUsername) {
      return toast.error("Special char and numbers not allowed in username.");
    } else if (
      clientUser.client === "" &&
      clientUser.departmentId === "" &&
      clientUser.fullName === "" &&
      clientUser.gender === "" &&
      clientUser.email === "" &&
      clientUser.userName === "" &&
      clientUser.password === "" &&
      clientUser.profileUrl === ""
    ) {
      return toast.error(isValidEmail || "Enter all details");
    } else {
      context.editActive === "client-user-edit"
        ? updateClient()
        : createClientUser();
    }
  };

  const uploadLogoToS3 = async (file) => {
    const uploadURL = await axios.get(`https://api.theeliteqa.com/api/S3Url`, {
      headers: {
        token: token,
      },
    });
    await axios.request({
      method: "PUT",
      headers: {
        "Content-Type": file.type,
      },
      url: uploadURL.data.uploadURL,
      data: file,
    });
    const imageURL = uploadURL.data.uploadURL.split("?")[0];
    return imageURL;
  };

  const addlogo = () => {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = async (e) => {
      setImageLoading(true);
      const logo = await uploadLogoToS3(e.target.files[0]);
      setImageLoading(false);
      // console.log(logo)
      setClientUser({ ...clientUser, profileUrl: logo });
    };
    input.click();
  };

  const cancelEdit = () => {
    context.changeEditActive("");
    context.addClientUserId("");
    context.addClientUserData(null);
    context.changeActive("viewclientuser");

    setClientUser({
      client: "",
      fullName: "",
      gender: "",
      email: "",
      departmentId: "",
      userName: "",
      password: "",
      profileUrl: "",
    });
  };

  const onSelectDepartment = (selectedItem) => {
    let id = selectedItem._id;
    setClientUser({ ...clientUser, departmentId: id });
  };

  const departmentItem = useMemo(
    () =>
      department.map((option) => ({
        key: option.id,
        label: option.department,
        ...option,
      })),
    [department.length > 0] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onSelectClient = (selectedItem) => {
    let id = selectedItem._id;
    setClientUser({ ...clientUser, client: id });
  };

  const clientItem = useMemo(
    () =>
      clients.map((option) => ({
        key: option.id,
        label: option.companyName,
        ...option,
      })),
    [clients] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const validateInputFields = (value) => {
    const input = /^[a-zA-Z\s]*$/;
    if (!input.test(value)) {
      return "Special char and numbers not allowed.";
    }
  };

  return (
    <div className="add-client-user-container">
      <div className="add-client-form">
        <div className="heading">
          <div className="left-side">
            <div className="sidebar"></div>
            <span>Add Client User Form</span>
          </div>
          <div className="button-container">
            {context.editActive === "client-user-edit" ? (
              <button className="cancel" onClick={cancelEdit}>
                <img src={cancel} alt="save" title="Cancel" />
                Cancel
              </button>
            ) : null}
            <button
              className="save"
              style={
                context.editActive === "client-user-edit"
                  ? { width: "112px" }
                  : null
              }
              onClick={validateData}
              title={
                context.editActive === "client-user-edit" ? "Update" : "Save"
              }
            >
              <img src={save} alt="save" />
              {loading ? (
                <i class="fa fa-circle-o-notch fa-spin"></i>
              ) : context.editActive === "client-user-edit" ? (
                "Update"
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>

        <div className="form-container1">
          <div className="logo-container">
            <div className="add-logo" onClick={addlogo}>
              <img src={addmedia} alt="addmedia" />
              <span>Add Profile Photo</span>
            </div>

            {imageLoading ? (
              <div className="loading-container">
                <i
                  className="fa fa-circle-o-notch fa-spin fa-3x"
                  style={{ color: "#FF6813" }}
                ></i>
              </div>
            ) : (
              <div>
                {clientUser.profileUrl !== "" &&
                clientUser.profileUrl !== "No Logo" ? (
                  <div className="logo">
                    <img src={clientUser.profileUrl} alt="" />
                    <img
                      src={close}
                      alt=""
                      className="removeLogo"
                      onClick={() => {
                        setClientUser({ ...clientUser, profileUrl: "No Logo" });
                      }}
                    />
                  </div>
                ) : null}
              </div>
            )}
          </div>

          <div className="row">
            <div className="input-container">
              <label>Client *</label>
              <DataListInput
                placeholder="Client"
                items={clientItem}
                onSelect={onSelectClient}
                dropdownClassName="dropdown"
                itemClassName="dropdownItem"
                activeItemClassName="activeItem"
              />
              {/* <select name="package" value={clientUser.client} onChange={handleChange("client")}>
                                <option value="">Select Client</option>
                                {
                                    clients.map((data, index) => {
                                        return <option value={data._id} key={index} selected={clientUser.client === data._id ? "selected" : null}>{data.companyName}</option>;
                                    })
                                }
                            </select> */}
            </div>

            <div className="input-container">
              <label>Full Name *</label>
              <input
                value={clientUser.fullName}
                type="text"
                placeholder="name"
                onChange={handleChange("fullName")}
                onKeyDown={validateTextInput}
              />
              {clientUser.fullName !== "" ? (
                <span
                  style={{
                    color: "red",
                  }}
                >
                  {validateInputFields(clientUser.fullName)}
                </span>
              ) : (
                <></>
              )}
            </div>

            <div className="input-container">
              <label>Gender *</label>
              <div className="gender-container">
                <button
                  style={
                    clientUser.gender === "male"
                      ? { border: "1.2px solid #00C49A", color: "#00C49A" }
                      : null
                  }
                  onClick={handleChange("gender")}
                  value={"male"}
                >
                  M
                </button>
                <button
                  style={
                    clientUser.gender === "female"
                      ? { border: "1.2px solid #00C49A", color: "#00C49A" }
                      : null
                  }
                  onClick={handleChange("gender")}
                  value={"female"}
                >
                  F
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="input-container">
              <label>Email *</label>
              <input
                value={clientUser.email}
                style={
                  clientUser?.email !== "" &&
                  isEmail(clientUser.email) === "Invalid Email"
                    ? { border: "1px solid red" }
                    : null
                }
                type="text"
                placeholder="name@work.com"
                onChange={handleChange("email")}
              />
              {clientUser?.email !== "" ? (
                <span
                  style={{
                    marginTop: "5px",
                    color: "red",
                  }}
                >
                  {isEmail(clientUser.email)}
                </span>
              ) : (
                <></>
              )}
            </div>

            <div className="input-container">
              <label>Department *</label>
              <DataListInput
                placeholder="Department"
                items={departmentItem}
                onSelect={onSelectDepartment}
                dropdownClassName="dropdown"
                itemClassName="dropdownItem"
                activeItemClassName="activeItem"
              />
              {/* <select name="package" value={clientUser.departmentId} onChange={handleChange("departmentId")}>
                                <option value="">Select Department</option>
                                {
                                    department.length && department?.map((data, index) => {
                                        return <option value={data._id} key={index} selected={clientUser?.departmentId === data._id ? "selected" : null}>{data.department}</option>;
                                    })
                                }
                            </select> */}
            </div>

            <div className="input-container">
              <label>Username *</label>
              <input
                value={clientUser.userName}
                type="text"
                placeholder="Enter username here"
                onChange={handleChange("userName")}
                onKeyDown={validateUsername}
              />
              {clientUser.userName !== "" ? (
                <span
                  style={{
                    color: "red",
                  }}
                >
                  {validateInputFields(clientUser.userName)}
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="row2">
            <div className="input-container">
              <label>Password *</label>
              <div>
                <input
                  value={clientUser.password}
                  type={passwordView ? "text" : "password"}
                  placeholder="Enter Password Here"
                  onChange={handleChange("password")}
                  style={{
                    width: "90%",
                  }}
                />
                {passwordView ? (
                  <svg
                    onClick={() => setpasswordView(!passwordView)}
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "20px", cursor: "pointer" }}
                  >
                    <g clip-path="url(#clip0_2171_2043)">
                      <path
                        d="M0.916992 11.0003C0.916992 11.0003 4.58366 3.66699 11.0003 3.66699C17.417 3.66699 21.0837 11.0003 21.0837 11.0003C21.0837 11.0003 17.417 18.3337 11.0003 18.3337C4.58366 18.3337 0.916992 11.0003 0.916992 11.0003Z"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2171_2043">
                        <rect width="22" height="22" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    onClick={() => setpasswordView(!passwordView)}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "20px", cursor: "pointer" }}
                  >
                    <path
                      d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
                      stroke="#AAAAAA"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                      stroke="#AAAAAA"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </div>
              {clientUser?.password !== "" ? (
                <span
                  style={{
                    marginTop: "5px",
                    color: "red",
                  }}
                >
                  {passwordStrength(clientUser?.password)}
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RightManageClientUser;
