import React from 'react'
import { Navigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode';

const ProtectedRoutes = ({ children }) => {

    const token = localStorage.getItem('token')
    if (token === null) return <Navigate to="/" />
    // decode the jwt token and check the expiration time
    const decoded = jwt_decode(token);
    const expirationTime = decoded.exp * 1000;

    // if token is not there redirect to login or token expired
    if (token === null || Date.now() >= expirationTime) {
        localStorage.clear();
        return <Navigate to="/" />;
    }
    return children
}

export default ProtectedRoutes