import React, { useState, useEffect, useContext } from "react";
import "./RightViewClient.css";
import trash from "../../assets/icons/trash.svg";
import axios from "axios";
import { toast } from "react-toastify";
import DataContext from "../../store/DataProvider";
import close from "../../assets/icons/close.svg";
import avatar from "../../assets/icons/Avatar.svg";
import view from "../../assets/icons/View.svg";
import edit from "../../assets/icons/edit.svg";

const RightViewClient = () => {
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const context = useContext(DataContext);
  const [isViewActive, setIsViewActive] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const token = localStorage.getItem("token");

  useEffect(() => {
    getClients();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getClients = async () => {
    try {
      setLoading(true);
      const response = await axios("https://api.theeliteqa.com/api/client/", {
        headers: {
          token: token,
        },
      });
      if (response.data !== undefined) {
        setClients(response.data.data);
        // console.log(clients.length)
        setTotalPage(Math.floor(response.data.data.length / 10) + 1);
        setLoading(false);
        setPages(clients.length / 10);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteClient = async (id) => {
    try {
      await axios.delete(`https://api.theeliteqa.com/api/client/remove/${id}`, {
        headers: {
          token: token,
        },
      });
      toast.success("Client deleted successfully.");
      let updatedData = clients.filter((data) => data._id !== id);
      setClients(updatedData);
    } catch (error) {
      toast.error("Failed to delete client.");
    }
  };

  const handleEditBtn = (data) => {
    context.changeEditActive("client-edit");
    context.addClientId(data._id);
    context.addClientData(data);
    context.changeActive("onboardclient");
  };

  const handleView = (data) => {
    setIsViewActive(true);
    setViewData(data);
  };

  function goToNextPage() {
    // console.log(pageNum, "   ", totalPage)
    if (pageNum >= totalPage) {
      return toast.error("No more Pages");
    } else {
      setCurrentPage((page) => page + 1);
      setPageNum(pageNum + 1);
    }
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
    setPageNum((pageNum) => pageNum - 1);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * 10 - 10;
    const endIndex = startIndex + 10;
    return clients.slice(startIndex, endIndex);
  };

  const searchClient = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `https://api.theeliteqa.com/api/client/search`,
        { searchText: searchText },
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.data !== undefined) {
        if (response.data.data.length === 0) {
          toast.error("There is no result for this search.");
          setLoading(false);
        } else {
          setClients(response.data.data);
          setTotalPage(Math.floor(response.data.data.length / 10) + 1);
          setLoading(false);
          setPages(clients.length / 10);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const viewClientPopUp = () => {
    return (
      <div className="view-client-container">
        <div className="card-container">
          <div className="heading1">
            <div className="left-side">
              <div className="sidebar"></div>
              <span>View Client</span>
            </div>
            <img
              src={close}
              alt="close"
              onClick={() => {
                setIsViewActive(false);
                setViewData(null);
              }}
            />
          </div>

          <div className="client-data-container">
            <div className="four-col-grid">
              <div className="input-image">
                <span>Logo</span>
                <div className="data">
                  {(viewData.logo !== "") & (viewData.logo !== "No Logo") ? (
                    <img src={viewData.logo} alt="logo" />
                  ) : null}
                </div>
              </div>
              <div className="input">
                <span>Company Name</span>
                <div className="data">
                  <span>{viewData.companyName}</span>
                </div>
              </div>
              <div className="input">
                <span>Company Email</span>
                <div className="data">
                  <span>{viewData.companyEmail}</span>
                </div>
              </div>
              <div className="input">
                <span>Phone</span>
                <div className="data">
                  <span>{viewData.contact}</span>
                </div>
              </div>
            </div>

            <div className="three-col-grid">
              <div className="input">
                <span>Website</span>
                <div className="data">
                  <span>{viewData.website}</span>
                </div>
              </div>
              <div className="input">
                <span>Package</span>
                <div className="data">
                  <span>{viewData.packageId?.name}</span>
                </div>
              </div>
              <div className="input">
                <span>Address</span>
                <div className="data-address">
                  <span>{viewData.companyAddress}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="tan-gst-container">
            <span>
              GSTIN: <span style={{ color: "#000" }}>{viewData.gstn}</span>
            </span>
            <span>
              TAN: <span style={{ color: "#000" }}>{viewData.tanNo}</span>
            </span>
          </div>

          <span className="contact-heading">Contact Person</span>
          <div className="contact-person-container">
            {viewData.contactPerson.map((data, index) => {
              return (
                <div className="contact-person-card" key={index}>
                  <img src={avatar} alt="avatar" />
                  <span className="name">{data.name}</span>
                  <span>{data.role}</span>
                  <span>{data.contactno}</span>
                  <span>{data.email}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const handleSearch = (e) => {
    if (e.target.value === "") {
      setPageNum(1);
      getClients();
    } else {
      setSearchText(e.target.value);
    }
  };

  return (
    <div className="right-view-client-container">
      <div className="list-clients">
        <div className="heading">
          <div className="title-group">
            <div className="sidebar"></div>
            <span>View Clients</span>
          </div>
          <div className="search-container">
            <input
              type="search"
              placeholder="Search here"
              onChange={(e) => {
                handleSearch(e);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  searchClient();
                }
              }}
            />
            <button
              style={{
                width: "100px",
                height: "40px",
                background: "#384455",
                marginLeft: "20px",
                color: "white",
                cursor: "pointer",
                borderRadius: "2px",
              }}
              onClick={searchClient}
            >
              Search
            </button>
          </div>
          <div className="pagination">
            {/* previous button */}
            <button
              onClick={goToPreviousPage}
              // className={`prev ${currentPage === 1 ? 'disabled' : ''}`}
              disabled={pageNum <= 1 ? "true" : ""}
              title="Previous"
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.41421 6L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289L5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893C7.09763 0.683418 7.09763 1.31658 6.70711 1.70711L2.41421 6Z"
                  fill="white"
                />
              </svg>
            </button>

            {/* show page numbers */}
            {/* {getPaginationGroup().map((item, index) => (
                            <button
                                key={index}
                                onClick={changePage}
                                className={`paginationItem ${currentPage === item ? 'active' : null}`}
                            >
                                <span>{item}</span>
                            </button>
                        ))} */}
            <span> {pageNum + "/" + totalPage}</span>

            {/* next button */}
            <button
              onClick={goToNextPage}
              className={`next ${currentPage === pages ? "disabled" : ""}`}
              disabled={pageNum >= totalPage ? "true" : ""}
              title="Next"
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
        {loading ? (
          <div className="loading-container">
            <i
              className="fa fa-circle-o-notch fa-spin fa-5x"
              style={{ color: "#FF6813" }}
            ></i>
          </div>
        ) : (
          <div className="staff-container">
            <table>
              <tr>
                <th>Sr.No</th>
                <th>Company Name</th>
                <th>Email</th>
                <th>Phone No</th>
                <th>Options</th>
              </tr>

              {/* {clients.map((data, index) => {
                            return (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{data.companyName}</td>
                                    <td>{data.companyEmail}</td>
                                    <td>{data.contact}</td>
                                    <td>
                                        <div className="options">
                                            <div title='Edit' className='edit' onClick={() => handleEditBtn(data)}>
                                                <img src={edit} alt="trash" />
                                            </div>

                                            <div title='Delete' className='delete' onClick={() => deleteClient(data._id)}>
                                                <img src={trash} alt="trash" />
                                            </div>

                                            <div title='View' className='view' onClick={() => handleView(data)}>
                                                <img src={view} alt="view" />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })} */}

              {getPaginatedData()?.map((data, index) => {
                return (
                  <tr>
                    <td>{index + 1 + 10 * (pageNum - 1)}</td>
                    <td>{data.companyName}</td>
                    <td>{data.companyEmail}</td>
                    <td>{data.contact}</td>
                    <td>
                      <div className="options">
                        <div
                          title="Edit"
                          className="edit"
                          onClick={() => handleEditBtn(data)}
                        >
                          <img src={edit} alt="trash" />
                        </div>

                        <div
                          title="Delete"
                          className="delete"
                          onClick={() => deleteClient(data._id)}
                        >
                          <img src={trash} alt="trash" />
                        </div>

                        <div
                          title="View"
                          className="view"
                          onClick={() => handleView(data)}
                        >
                          <img src={view} alt="view" />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        )}

        {isViewActive ? viewClientPopUp() : null}
      </div>
    </div>
  );
};

export default RightViewClient;
