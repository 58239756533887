import React from 'react';
import close from "../../assets/icons/close.svg";
import './EditSkillPopup.css';

const EditSkillPopup = ({ skills, handleChange, cancelEdit, loading, updateSkill, search, searchTopic, addTopic, searchData, userTopic, removeTopic, editType }) => {
    const filteredTopic = userTopic.filter((data) => data.topicId !== null);
    return (
        <div className='edit-skill-popup'>
            <div className='skill-edit'>
                <div className="heading">
                    <div className="left-side">
                        <div className="sidebar"></div>
                        <span>{editType === "assign" ? "Assign" : "Edit Skill"}</span>
                    </div>

                    <div className='button-container'>
                        <button onClick={updateSkill}>{loading ? "Loading..." : "Update"}</button>
                        <img src={close} alt="close" onClick={cancelEdit} />
                    </div>
                </div>

                <div className='form-container'>
                    <div className='input'>
                        <label>Selected Skill</label>
                        <input type="text" value={skills} placeholder="Skill name" onChange={editType === "assign" ? null : handleChange("skills")} />
                    </div>

                    <div className='input' style={{ marginTop: 15 }}>
                        <label>Assign Topics</label>
                        <input type="text" placeholder="Search topics" onChange={searchData} />
                    </div>

                    <React.Fragment>
                        {
                            search !== "" && searchTopic.length !== 0 ? <div className='search-container'>
                                {
                                    searchTopic.map((data) => {
                                        return <span onClick={() => addTopic(data)}>{data.topic}</span>;
                                    })
                                }
                            </div> : null
                        }
                    </React.Fragment>

                    <React.Fragment>
                        {
                            filteredTopic.length !== 0 ? <div className="tag-container">
                                {
                                    filteredTopic.map((data) => {
                                        return <div className='tag'>
                                            {data.topicId.topic}
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => removeTopic(data)}>
                                                <circle cx="10" cy="10" r="10" fill="#E2E8EB" />
                                                <path d="M13 7L7 13" stroke="#FF6812" stroke-linecap="round" />
                                                <path d="M13 13L7.00019 7.00019" stroke="#FF6812" stroke-linecap="round" />
                                            </svg>
                                        </div>;
                                    })
                                }
                            </div> : null
                        }
                    </React.Fragment>
                </div>
            </div>
        </div>
    );
};

export default EditSkillPopup;