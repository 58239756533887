import React, { useState, useEffect } from "react";
import "./RightAddJobRole.css";
import axios from "axios";
import { toast } from "react-toastify";
import trash from "../../assets/icons/trash.svg";
import tag from "../../assets/icons/tag.svg";
import view from "../../assets/icons/View.svg";
import edit from "../../assets/icons/edit.svg";
import EditRolePopup from "../edit_role_popup/EditRolePopup";
import ViewRolePopup from "../view_role_popup/ViewRolePopup";
import { validateSpecialChar } from "../../functions/validateSpecialChar";
import { validateTextInput } from "../../functions/validateTextInput";

const RightAddJobRole = () => {
  const [loading, setLoading] = useState(false);
  const [isEditActive, setIsEditActive] = useState(false);
  const [skill, setSkill] = useState([]);
  const [role, setRole] = useState([]);
  const [roleId, setRoleId] = useState("");
  const [searchSkill, setSearchSkill] = useState([]);
  const [search, setSearch] = useState("");
  const [userSkills, setUserSkills] = useState([]);
  const [viewData, setViewData] = useState(null);
  const [isViewActive, setIsViewActive] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [editType, setEditType] = useState("");

  const [roleDetails, setRoleDetails] = useState({
    jobrole: "",
    skills: [],
  });

  const token = localStorage.getItem("token");

  useEffect(() => {
    getSkills();
    getJobRoles();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const searchSkills = (value) => {
    let filteredSkill = skill.filter((skill) =>
      skill.skills.toLowerCase().includes(value.trim().toLowerCase())
    );
    if (filteredSkill.length === 0) {
      setSearchSkill([]);
    } else {
      setSearchSkill(filteredSkill);
    }
  };

  const handleSearchChange = (e) => {
    searchSkills(e.target.value);
    setSearch(e.target.value);
  };

  const getJobRoles = async () => {
    try {
      setRoleLoading(true);
      const response = await axios(
        "https://api.theeliteqa.com/api/jobrole/getroleTable",
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.status === 200) {
        setRole(response.data.roleTables);
        setTotalPage(Math.floor(response.data.roleTables.length / 10) + 1);
        setLoading(false);
        setPages(role.length / 10);
        setRoleLoading(false);
      }
    } catch (error) {
      setRoleLoading(false);
      toast.error("Failed to get roles");
    }
  };

  const getSkills = async () => {
    try {
      const response = await axios(
        "https://api.theeliteqa.com/api/skill/getskill",
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.data !== undefined) {
        setSkill(response.data.skills);
      }
    } catch (error) {
      toast.error("Failed to get skills");
    }
  };

  const createJobRole = async () => {
    try {
      setLoading(true);
      const body = {
        roleDetails,
      };
      const response = await axios.post(
        "https://api.theeliteqa.com/api/jobrole/createroleTable",
        body.roleDetails,
        {
          headers: {
            token: token,
          },
        }
      );
      setRole((prev) => [response.data.roleTable, ...prev]);
      toast.success("Role created successfully.");
      setRoleDetails({
        jobrole: "",
        skills: [],
      });
      setLoading(false);
    } catch (error) {
      if (error.response.data.error.keyValue.jobrole === roleDetails.jobrole) {
        toast.error("Job role already exits.");
        setLoading(false);
      } else {
        toast.error("Failed to create job role.");
        setLoading(false);
      }
    }
  };

  const updateRole = async () => {
    try {
      setLoading(true);
      const body = {
        roleDetails,
      };

      await axios.put(
        `https://api.theeliteqa.com/api/jobrole/update/${roleId}`,
        body.roleDetails,
        {
          headers: {
            token: token,
          },
        }
      );
      toast.success("Role updated successfully.");
      setIsEditActive(false);
      setSearch("");
      setRoleDetails({
        jobrole: "",
        skills: [],
      });
      setLoading(false);
      getJobRoles();
    } catch (error) {
      toast.error("Failed to update role.");
    }
  };

  const cancelEdit = () => {
    setIsEditActive(false);
    setRoleId("");
    setRoleDetails({
      jobrole: "",
      skills: [],
    });
    setSearch("");
    setUserSkills([]);
  };

  const handleEdit = (data) => {
    setIsEditActive(true);
    setRoleId(data._id);
    setRoleDetails({
      jobrole: data.jobrole,
      skills: data.skills,
    });
    setUserSkills(data.skills);
  };

  const handleChange = (name) => (event) => {
    const value = event.target.value;
    if (name === "jobrole") {
      setRoleDetails({ ...roleDetails, jobrole: value });
    } else {
      let skillArray = [{ skillId: value }];
      setRoleDetails({ ...roleDetails, skills: skillArray });
    }
  };

  const deleteRole = async (id) => {
    try {
      await axios.delete(
        `https://api.theeliteqa.com/api/jobrole/deleteroleTable/${id}`,
        {
          headers: {
            token: token,
          },
        }
      );
      toast.error("Role deleted successfully.");
      let updatedData = role.filter((data) => data._id !== id);
      setRole(updatedData);
    } catch (error) {
      toast.error("Failed to delete role.");
    }
  };

  const addSkill = (data) => {
    let obj = { skillId: data };
    setRoleDetails({
      ...roleDetails,
      skills: [...roleDetails.skills, { skillId: data, _id: data._id }],
    });
    setUserSkills([...userSkills, obj]);
  };

  const removeSkills = (data) => {
    let itemRemoved = roleDetails.skills.filter(
      (skill) => skill.skillId._id !== data.skillId._id
    );
    setRoleDetails({ ...roleDetails, skills: itemRemoved });
    setUserSkills(itemRemoved);
  };

  const handleView = (data) => {
    setIsViewActive(true);
    setViewData(data);
  };

  const validateData = () => {
    if (roleDetails.jobrole === "") {
      toast.error("Role cannot be empty");
    } else {
      createJobRole();
    }
  };

  const closeView = () => {
    setIsViewActive(false);
    setViewData(null);
  };

  function goToNextPage() {
    // console.log(pageNum, "   ", totalPage)
    if (pageNum >= totalPage) {
      return toast.error("No more Pages");
    } else {
      setCurrentPage((page) => page + 1);
      setPageNum(pageNum + 1);
    }
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
    setPageNum((pageNum) => pageNum - 1);
  }

  // function changePage(event) {
  //     const pageNumber = Number(event.target.textContent);
  //     setCurrentPage(pageNumber);
  // }

  const getPaginatedData = () => {
    const startIndex = currentPage * 10 - 10;
    const endIndex = startIndex + 10;
    return role.slice(startIndex, endIndex);
  };

  // const getPaginationGroup = () => {
  //     let start = Math.floor((currentPage - 1) / 5) * 5;
  //     return new Array(5).fill().map((_, idx) => start + idx + 1);
  // };

  const searchJobRole = async () => {
    try {
      setRoleLoading(true);
      const response = await axios.post(
        `https://api.theeliteqa.com/api/jobrole/search`,
        { searchText: searchText },
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.data !== undefined) {
        setRole(response.data.data);
        setTotalPage(Math.floor(response.data.data.length / 10) + 1);
        setLoading(false);
        setPages(role.length / 10);
        setRoleLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    if (e.target.value === "") {
      setPageNum(1);
      getJobRoles();
    } else {
      setSearchText(e.target.value);
    }
  };

  return (
    <div className="add-role-container">
      <div className="add-topic-form-container">
        <div className="heading">
          <div className="title">
            <div className="sidebar"></div>
            <span>Add Role</span>
          </div>

          <div className="form">
            <input
              type="text"
              placeholder="Enter role here"
              name="jobrole"
              onChange={handleChange("jobrole")}
              value={isEditActive ? "" : roleDetails.jobrole}
              onKeyDown={(e) => {
                validateSpecialChar(e);
                validateTextInput(e);
              }}
            />
            <button
              onClick={() => {
                validateData();
              }}
              title="Add Role"
            >
              {loading ? <i class="fa fa-circle-o-notch fa-spin"></i> : "+"}
            </button>
          </div>
        </div>
      </div>

      <div className="list-topic">
        <div className="heading">
          <div className="title-group">
            <div className="sidebar"></div>
            <span>Added Roles</span>
          </div>
          <div className="search-container">
            <input
              type="search"
              placeholder="Search here"
              onChange={(e) => {
                handleSearch(e);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  searchJobRole();
                }
              }}
            />
            <button
              style={{
                width: "100px",
                height: "40px",
                background: "#384455",
                marginLeft: "20px",
                color: "white",
                cursor: "pointer",
                borderRadius: "2px",
              }}
              onClick={searchJobRole}
            >
              Search
            </button>
          </div>
          <div className="pagination">
            {/* previous button */}
            <button
              onClick={goToPreviousPage}
              // className={`prev ${currentPage === 1 ? 'disabled' : ''}`}
              disabled={pageNum <= 1 ? "true" : ""}
              title="Previous"
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.41421 6L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289L5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893C7.09763 0.683418 7.09763 1.31658 6.70711 1.70711L2.41421 6Z"
                  fill="white"
                />
              </svg>
            </button>

            {/* show page numbers */}
            {/* {getPaginationGroup().map((item, index) => (
                            <button
                                key={index}
                                onClick={changePage}
                                className={`paginationItem ${currentPage === item ? 'active' : null}`}
                            >
                                <span>{item}</span>
                            </button>
                        ))} */}
            <span> {pageNum + "/" + totalPage}</span>

            {/* next button */}
            <button
              onClick={goToNextPage}
              className={`next ${currentPage === pages ? "disabled" : ""}`}
              disabled={pageNum >= totalPage ? "true" : ""}
              title="Next"
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
        {roleLoading ? (
          <div className="loader-container">
            <i
              class="fa fa-circle-o-notch fa-spin fa-5x"
              style={{
                color: "#FF6813",
              }}
            ></i>
          </div>
        ) : (
          <div className="topic-container">
            <table>
              <tr>
                <th>Sr.No</th>
                <th>Role</th>
                <th>Assign Skills</th>
                <th>Options</th>
              </tr>

              {getPaginatedData()?.map((data, index) => {
                return (
                  <tr>
                    <td>{index + 1 + 10 * (pageNum - 1)}</td>
                    <td>{data.jobrole}</td>
                    <td
                      onClick={() => {
                        handleView(data);
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <div className="assign-skills">
                        {data.skills.length === 0 ? (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleEdit(data);
                              setEditType("assign");
                            }}
                            title="Assign"
                          >
                            Assign
                          </button>
                        ) : (
                          <div className="skills" style={{ cursor: "pointer" }}>
                            <img src={tag} alt="skill" />
                            {data.skills.map((skill, index) => {
                              if (index < 3) {
                                return (
                                  <span style={{ marginRight: 5 }} key={index}>
                                    {skill.skillId?.skills}
                                    {index === 3 ||
                                    data.skills.length - 1 === index
                                      ? null
                                      : ","}
                                  </span>
                                );
                              } else {
                                return null;
                              }
                            })}
                            {data.skills.length > 3 ? (
                              <span>{`+${data.skills.length - 3} more`}</span>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="options">
                        <div
                          title="Edit"
                          className="edit"
                          onClick={() => {
                            handleEdit(data);
                            setEditType("edit");
                          }}
                        >
                          <img src={edit} alt="edit" />
                        </div>

                        <div
                          title="Delete"
                          className="delete"
                          onClick={() => deleteRole(data._id)}
                        >
                          <img src={trash} alt="trash" />
                        </div>

                        <div
                          title="View"
                          className="view"
                          onClick={() => handleView(data)}
                        >
                          <img src={view} alt="view" />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        )}
      </div>

      {isEditActive ? (
        <EditRolePopup
          cancelEdit={cancelEdit}
          updateRole={updateRole}
          role={roleDetails.jobrole}
          search={search}
          handleChange={handleChange}
          handleSearchChange={handleSearchChange}
          addSkill={addSkill}
          searchSkill={searchSkill}
          userSkills={userSkills}
          removeSkills={removeSkills}
          editType={editType}
          loading={loading}
        />
      ) : null}

      {isViewActive ? (
        <ViewRolePopup
          viewData={viewData}
          role={viewData.jobrole}
          closeView={closeView}
        />
      ) : null}
    </div>
  );
};

export default RightAddJobRole;
