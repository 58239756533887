import React, { useContext } from "react";
import "./Sidebar.css";
import { useNavigate } from "react-router-dom";
import DataContext from "../../store/DataProvider";
import user from "../../assets/icons/user.svg";
import briefcase from "../../assets/icons/briefcase.svg";
import book from "../../assets/icons/book.svg";
import users from "../../assets/icons/users.svg";
import Package from "../../assets/icons/package.svg";
import sidebar from "../../assets/icons/sidebar.svg";
import calendar from "../../assets/icons/calendar.svg";
import globe from "../../assets/icons/globe.svg";
import home from "../../assets/icons/home.svg";
import logOut from "../../assets/icons/logOut.svg";
import manageuser from "../../assets/icons/manageuser.svg";


// assets
import navbarLine from "../../assets/icons/navbarLine.svg";
import navbarTwoLines from "../../assets/icons/navbarTwoLines.svg";
import { toast } from 'react-toastify';

const Sidebar = () => {
    const context = useContext(DataContext);
    let navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem("token");
        toast.success("Logout Successfully.");
        navigate("/");
    };

    const handleDropDown = (value) => {
        if (context.active === value) {
            context.changeActive("home");
        } else {
            context.changeActive(value);
        }
    };

    // old SVG color was #6F767E

    return (
        <div className="sidebar-container">
            <div className="button-container">
                <div
                    className={context.active === "home" ? "active-button" : "button"}
                    onClick={() => {
                        context.changeEditActive('');
                        context.addClientId("");
                        context.addClientData(null);
                        context.addOrderData(null);
                        context.addOrderId("");
                        handleDropDown("home");
                    }}
                >
                    <div className="left">
                        <div className="left">
                            <img src={home} alt="home" />
                            <span>Home</span>
                        </div>
                    </div>
                </div>
                <div
                    className={
                        context.active === "manageclient" ||
                            context.active === "onboardclient" ||
                            context.active === "viewclient" ||
                            context.active === "viewtimeline"
                            ? "active-button"
                            : "button"
                    }
                    onClick={() => {
                        handleDropDown("manageclient");
                    }}
                >
                    <div className="left">
                        {/* <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12ZM12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14Z"
                                fill={
                                    context.active === "manageclient" ||
                                        context.active === "onboardclient" ||
                                        context.active === "viewclient" ||
                                        context.active === "viewtimeline"
                                        ? "#FFFFFF"
                                        : "#FFFFFF"
                                }
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M18.5588 19.5488C20.6672 17.7154 22 15.0134 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 15.0134 3.33284 17.7154 5.44116 19.5488C7.19693 21.0756 9.49052 22 12 22C14.4162 22 16.6323 21.143 18.3609 19.7165C18.4276 19.6614 18.4936 19.6055 18.5588 19.5488ZM12.2579 19.9959C12.1723 19.9986 12.0863 20 12 20C11.9914 20 11.9827 20 11.9741 20C11.8937 19.9997 11.8135 19.9983 11.7337 19.9956C10.3914 19.9517 9.13273 19.5772 8.03655 18.9508C8.95181 17.7632 10.3882 17 12 17C13.6118 17 15.0482 17.7632 15.9634 18.9508C14.865 19.5785 13.6033 19.9533 12.2579 19.9959ZM17.5624 17.7498C16.2832 16.0781 14.2675 15 12 15C9.73249 15 7.7168 16.0781 6.43759 17.7498C4.93447 16.2953 4 14.2568 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 14.2568 19.0655 16.2953 17.5624 17.7498Z"
                                fill={
                                    context.active === "manageclient" ||
                                        context.active === "onboardclient" ||
                                        context.active === "viewclient" ||
                                        context.active === "viewtimeline"
                                        ? "#FFFFFF"
                                        : "#FFFFFF"
                                }
                            />
                        </svg> */}
                        <img src={user} alt="user" />
                        <span>Manage Client</span>
                    </div>
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    // style={{
                    //     marginLeft: "90px",
                    // }}
                    >
                        <path
                            d="M6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L6.70711 8.29289ZM12 15L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L12 15ZM18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L18.7071 9.70711ZM5.29289 9.70711L11.2929 15.7071L12.7071 14.2929L6.70711 8.29289L5.29289 9.70711ZM12.7071 15.7071L18.7071 9.70711L17.2929 8.29289L11.2929 14.2929L12.7071 15.7071Z"
                            fill={
                                context.active === "manageclient" ||
                                    context.active === "onboardclient" ||
                                    context.active === "viewclient" ||
                                    context.active === "viewtimeline"
                                    ? "#FFFFFF"
                                    : "#FFFFFF"
                            }
                        />
                    </svg>
                </div>

                {context.active === "manageclient" ||
                    context.active === "onboardclient" ||
                    context.active === "viewclient" ||
                    context.active === "viewtimeline" ? (
                    <div className="navbarLine">
                        <div>
                            <img src={navbarLine} alt="" style={{ height: "100%", marginLeft: "15px" }} />
                        </div>
                        <div className="collapse">
                            <span
                                onClick={() => {
                                    handleDropDown("onboardclient");
                                }}
                                style={
                                    context.active === "onboardclient" ||
                                        context.active === "manageclient"
                                        ? { color: "#FF6812" }
                                        : null
                                }
                            >
                                Onboard
                            </span>
                            <span
                                onClick={() => {
                                    handleDropDown("viewclient");
                                }}
                                style={
                                    context.active === "viewclient" ? { color: "#FF6812" } : null
                                }
                            >
                                View
                            </span>
                            <span
                                onClick={() => {
                                    handleDropDown("viewtimeline");
                                }}
                                style={
                                    context.active === "viewtimeline"
                                        ? { color: "#FF6812" }
                                        : null
                                }
                            >
                                View Timeline
                            </span>
                        </div>
                    </div>
                ) : null}

                <div
                    className={
                        context.active === "manageclientuser" ||
                            context.active === "clientuser" ||
                            context.active === "viewclientuser"
                            ? "active-button"
                            : "button"
                    }
                    onClick={() => {
                        context.changeEditActive('');
                        context.addClientId("");
                        context.addClientData(null);
                        context.addOrderData(null);
                        context.addOrderId("");
                        handleDropDown("manageclientuser");
                    }}
                >
                    <div className="left">
                        <img src={manageuser} alt="user" />
                        <span>Manage User</span>
                    </div>
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    // style={{
                    //     marginLeft: "85px",
                    // }}
                    >
                        <path
                            d="M6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L6.70711 8.29289ZM12 15L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L12 15ZM18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L18.7071 9.70711ZM5.29289 9.70711L11.2929 15.7071L12.7071 14.2929L6.70711 8.29289L5.29289 9.70711ZM12.7071 15.7071L18.7071 9.70711L17.2929 8.29289L11.2929 14.2929L12.7071 15.7071Z"
                            fill={
                                context.active === "manageclientuser" ||
                                    context.active === "clientuser" ||
                                    context.active === "viewclientuser"
                                    ? "#FFFFFF"
                                    : "#FFFFFF"
                            }
                        />
                    </svg>
                </div>

                {context.active === "manageclientuser" ||
                    context.active === "clientuser" ||
                    context.active === "viewclientuser" ? (
                    <div className="navbarLine" style={{ marginBottom: "10px", marginLeft: "35px" }} >
                        <div>
                            <img
                                src={navbarTwoLines}
                                alt=""
                                style={{ height: "100%", marginLeft: "20px" }}
                            />
                        </div>
                        <div
                            className="collapse"
                            style={{
                                height: "90px",
                            }}
                        >
                            <span
                                onClick={() => {
                                    handleDropDown("clientuser");
                                }}
                                style={
                                    context.active === "manageclientuser" ||
                                        context.active === "clientuser"
                                        ? { color: "#FF6812", marginTop: "18px" }
                                        : { marginTop: "18px" }
                                }
                            >
                                Generate
                            </span>
                            <span
                                onClick={() => {
                                    handleDropDown("viewclientuser");
                                }}
                                style={
                                    context.active === "viewclientuser"
                                        ? { color: "#FF6812", marginTop: "33px" }
                                        : { marginTop: "33px" }
                                }
                            >
                                View
                            </span>
                        </div>
                    </div>
                ) : null}

                <div
                    className={
                        context.active === "manageorders" ||
                            context.active === "generateorders" ||
                            context.active === "vieworders"
                            ? "active-button"
                            : "button"
                    }
                    onClick={() => {
                        context.changeEditActive('');
                        context.addClientId("");
                        context.addClientData(null);
                        context.addOrderData(null);
                        context.addOrderId("");
                        handleDropDown("manageorders");
                    }}
                >
                    <div className="left">
                        <img src={briefcase} alt="briefcase" />
                        <span>Manage Orders</span>
                    </div>
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    // style={{
                    //     marginLeft: "85px",
                    // }}
                    >
                        <path
                            d="M6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L6.70711 8.29289ZM12 15L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L12 15ZM18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L18.7071 9.70711ZM5.29289 9.70711L11.2929 15.7071L12.7071 14.2929L6.70711 8.29289L5.29289 9.70711ZM12.7071 15.7071L18.7071 9.70711L17.2929 8.29289L11.2929 14.2929L12.7071 15.7071Z"
                            fill={
                                context.active === "manageorders" ||
                                    context.active === "generateorders" ||
                                    context.active === "vieworders"
                                    ? "#FFFFFF"
                                    : "#FFFFFF"
                            }
                        />
                    </svg>
                </div>

                {context.active === "manageorders" ||
                    context.active === "generateorders" ||
                    context.active === "vieworders" ? (
                    <div className="navbarLine" style={{ marginBottom: "10px", marginLeft: "35px" }} >
                        <div>
                            <img
                                src={navbarTwoLines}
                                alt=""
                                style={{ height: "100%", marginLeft: "20px" }}
                            />
                        </div>
                        <div
                            className="collapse"
                            style={{
                                height: "90px",
                            }}
                        >
                            <span
                                onClick={() => {
                                    handleDropDown("generateorders");
                                }}
                                style={
                                    context.active === "generateorders" ||
                                        context.active === "manageorders"
                                        ? { color: "#FF6812", marginTop: "18px" }
                                        : { marginTop: "18px" }
                                }
                            >
                                Generate
                            </span>
                            <span
                                onClick={() => {
                                    handleDropDown("vieworders");
                                }}
                                style={
                                    context.active === "vieworders"
                                        ? { color: "#FF6812", marginTop: "33px" }
                                        : { marginTop: "33px" }
                                }
                            >
                                View
                            </span>
                        </div>
                    </div>
                ) : null}

                <div
                    className={
                        context.active === "librarysettings" ||
                            context.active === "roles" ||
                            context.active === "skills" ||
                            context.active === "topics"
                            ? "active-button"
                            : "button"
                    }
                    onClick={() => {
                        context.changeEditActive('');
                        context.addClientId("");
                        context.addClientData(null);
                        context.addOrderData(null);
                        context.addOrderId("");
                        handleDropDown("librarysettings");
                    }}
                >
                    <div className="left">
                        <img src={book} alt="book" />
                        <span>Library Settings</span>
                    </div>
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    // style={{
                    //     marginLeft: "88px",
                    // }}
                    >
                        <path
                            d="M6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L6.70711 8.29289ZM12 15L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L12 15ZM18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L18.7071 9.70711ZM5.29289 9.70711L11.2929 15.7071L12.7071 14.2929L6.70711 8.29289L5.29289 9.70711ZM12.7071 15.7071L18.7071 9.70711L17.2929 8.29289L11.2929 14.2929L12.7071 15.7071Z"
                            fill={
                                context.active === "librarysettings" ||
                                    context.active === "roles" ||
                                    context.active === "skills" ||
                                    context.active === "topics"
                                    ? "#FFFFFF"
                                    : "#FFFFFF"
                            }
                        />
                    </svg>
                </div>

                {context.active === "librarysettings" ||
                    context.active === "roles" ||
                    context.active === "skills" ||
                    context.active === "topics" ? (
                    <div className="navbarLine">
                        <div>
                            <img src={navbarLine} alt="" style={{ height: "100%", marginLeft: "15px" }} />
                        </div>
                        <div
                            className="collapse"
                            style={{
                                height: "130px",
                            }}
                        >
                            <span
                                onClick={() => {
                                    handleDropDown("roles");
                                }}
                                style={
                                    context.active === "roles" ||
                                        context.active === "librarysettings"
                                        ? { color: "#FF6812" }
                                        : null
                                }
                            >
                                Roles
                            </span>
                            <span
                                onClick={() => {
                                    handleDropDown("skills");
                                }}
                                style={
                                    context.active === "skills" ? { color: "#FF6812" } : null
                                }
                            >
                                Skills
                            </span>

                            <span
                                onClick={() => {
                                    handleDropDown("topics");
                                }}
                                style={
                                    context.active === "topics" ? { color: "#FF6812" } : null
                                }
                            >
                                Topics
                            </span>
                        </div>
                    </div>
                ) : null}

                <div
                    className={
                        context.active === "managestaff" ? "active-button" : "button"
                    }
                    onClick={() => {
                        context.changeEditActive('');
                        context.addClientId("");
                        context.addClientData(null);
                        context.addOrderData(null);
                        context.addOrderId("");
                        handleDropDown("managestaff");
                    }}
                >
                    <div className="left">
                        <img src={users} alt="users" />
                        <span>Manage Staff</span>
                    </div>
                </div>

                <div
                    className={
                        context.active === "managepackage" ? "active-button" : "button"
                    }
                    onClick={() => {
                        context.changeEditActive('');
                        context.addClientId("");
                        context.addClientData(null);
                        context.addOrderData(null);
                        context.addOrderId("");
                        handleDropDown("managepackage");
                    }}
                >
                    <div className="left">
                        <img src={Package} alt="package" />
                        <span>Manage Package</span>
                    </div>
                </div>
                <div
                    className={
                        context.active === "managedepartment" ? "active-button" : "button"
                    }
                    onClick={() => {
                        context.changeEditActive('');
                        context.addClientId("");
                        context.addClientData(null);
                        context.addOrderData(null);
                        context.addOrderId("");
                        handleDropDown("managedepartment");
                    }}
                >
                    <div className="left">
                        <img src={sidebar} alt="sidebar" />
                        <span>Manage Department</span>
                    </div>
                </div>

                <div
                    className={
                        context.active === "managevalidity" ? "active-button" : "button"
                    }
                    onClick={() => {
                        context.changeEditActive('');
                        context.addClientId("");
                        context.addClientData(null);
                        context.addOrderData(null);
                        context.addOrderId("");
                        handleDropDown("managevalidity");
                    }}
                >
                    <div className="left">
                        <img src={calendar} alt="calender" />
                        <span>Manage Validity</span>
                    </div>
                </div>

                <div
                    className={
                        context.active === "managewebsitecontents" ||
                            context.active === "manageblog" ||
                            context.active === "manageevents" ||
                            context.active === "onboardevents" ||
                            context.active === "viewEvent"
                            ? "active-button"
                            : "button"
                    }
                    onClick={() => {
                        context.changeEditActive('');
                        context.addClientId("");
                        context.addClientData(null);
                        context.addOrderData(null);
                        context.addOrderId("");
                        handleDropDown("managewebsitecontents");
                    }}
                >
                    <div className="left">
                        <img src={globe} alt="globe" />
                        <span>Manage Website</span>
                    </div>
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="showMore"
                    >
                        <path
                            d="M6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L6.70711 8.29289ZM12 15L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L12 15ZM18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L18.7071 9.70711ZM5.29289 9.70711L11.2929 15.7071L12.7071 14.2929L6.70711 8.29289L5.29289 9.70711ZM12.7071 15.7071L18.7071 9.70711L17.2929 8.29289L11.2929 14.2929L12.7071 15.7071Z"
                            fill={
                                context.active === "managewebsitecontents" ||
                                    context.active === "manageblog" ||
                                    context.active === "manageevents" ||
                                    context.active === "onboardevents" ||
                                    context.active === "viewEvent"
                                    ? "#FFFFFF"
                                    : "#FFFFFF"
                            }
                        />
                    </svg>
                </div>
                {context.active === "managewebsitecontents" ||
                    context.active === "manageblog" ||
                    context.active === "manageevents" ||
                    context.active === "onboardevents" ||
                    context.active === "viewEvent" ? (
                    <div className="navbarLine" style={{ marginBottom: "10px" }} >
                        <div>
                            <img
                                src={navbarTwoLines}
                                alt=""
                                style={{ height: "100%", marginLeft: "35px" }}
                            />
                        </div>
                        <div
                            className="collapse svgSpan"
                            style={{
                                height: "90px",
                            }}
                        >
                            <span
                                onClick={() => {
                                    handleDropDown("manageblog");
                                }}
                                style={
                                    context.active === "manageblog" ||
                                        context.active === "managewebsitecontents"
                                        ? { color: "#FF6812", marginTop: "25px" }
                                        : { marginTop: "18px" }
                                }
                            >

                                <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 21.4V2.6C4 2.26863 4.26863 2 4.6 2H16.2515C16.4106 2 16.5632 2.06321 16.6757 2.17574L19.8243 5.32426C19.9368 5.43679 20 5.5894 20 5.74853V21.4C20 21.7314 19.7314 22 19.4 22H4.6C4.26863 22 4 21.7314 4 21.4Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M16 5.4V2.35355C16 2.15829 16.1583 2 16.3536 2C16.4473 2 16.5372 2.03725 16.6036 2.10355L19.8964 5.39645C19.9628 5.46275 20 5.55268 20 5.64645C20 5.84171 19.8417 6 19.6464 6H16.6C16.2686 6 16 5.73137 16 5.4Z" fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M8 10L16 10" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M8 18L16 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M8 14L12 14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <a href="https://blog.theeliteqa.com/ghost/#/signin" target="_blank" rel="noreferrer"> Manage Blog </a>
                            </span>
                            <div className="nested-collapse" onClick={() => {
                                handleDropDown("manageevents");
                            }}
                            >

                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                <span

                                    style={
                                        context.active === "manageevents" ||
                                            context.active === "onboardevents" ||
                                            context.active === "viewEvent"
                                            ? { color: "#FF6812" }
                                            : null
                                    }
                                >
                                    Manage Events
                                </span>
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                        marginLeft: "13px",
                                    }}
                                >
                                    <path
                                        d="M6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L6.70711 8.29289ZM12 15L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L12 15ZM18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L18.7071 9.70711ZM5.29289 9.70711L11.2929 15.7071L12.7071 14.2929L6.70711 8.29289L5.29289 9.70711ZM12.7071 15.7071L18.7071 9.70711L17.2929 8.29289L11.2929 14.2929L12.7071 15.7071Z"
                                        fill={
                                            context.active === "manageevents" ||
                                                context.active === "onboardevents" ||
                                                context.active === "viewEvent" ? "#FF6812" : "#FFFFFF"
                                        }
                                    />
                                </svg>
                            </div>

                        </div>
                    </div>
                ) : null}
                {context.active === "manageevents" ||
                    context.active === "onboardevents" ||
                    context.active === "viewEvent" ? (
                    <div className="navbarLine2">
                        <div>
                            <img
                                src={navbarTwoLines}
                                alt=""
                                style={{ height: "84%", marginLeft: "29px" }}
                            />
                        </div>
                        <div className="collapse">
                            <span
                                onClick={() => {
                                    handleDropDown("onboardevents");
                                }}
                                style={
                                    context.active === "onboardevents" ||
                                        context.active === "manageevents"
                                        ? { color: "#FF6812" }
                                        : null
                                }
                            >
                                Onboard
                            </span>
                            <span
                                onClick={() => {
                                    handleDropDown("viewEvent");
                                }}
                                style={
                                    context.active === "viewEvent" ? { color: "#FF6812" } : null
                                }
                            >
                                View
                            </span>

                        </div>
                    </div>
                ) : null}

                <div
                    className={
                        context.active === "manageparameters" ? "active-button" : "button"
                    }
                    onClick={() => {
                        // context.changeEditActive('');
                        // context.addClientId("");
                        // context.addClientData(null);
                        // context.addOrderData(null);
                        // context.addOrderId("");
                        handleDropDown("manageparameters");
                    }}
                >
                    <div className="left">
                        <img src={calendar} alt="calender" />
                        <span>Manage Health Score</span>
                    </div>
                </div>
                <div
                    className={
                        context.active === "manageexperience" ? "active-button" : "button"
                    }
                    onClick={() => {
                        // context.changeEditActive('');
                        // context.addClientId("");
                        // context.addClientData(null);
                        // context.addOrderData(null);
                        // context.addOrderId("");
                        handleDropDown("manageexperience");
                    }}
                >
                    <div className="left">
                        <img src={calendar} alt="calender" />
                        <span>Manage Experience</span>
                    </div>
                </div>

                <div
                    className={
                        context.active === "managelevel" ? "active-button" : "button"
                    }
                    onClick={() => {
                        // context.changeEditActive('');
                        // context.addClientId("");
                        // context.addClientData(null);
                        // context.addOrderData(null);
                        // context.addOrderId("");
                        handleDropDown("managelevel");
                    }}
                >
                    <div className="left">
                        <img src={calendar} alt="calender" />
                        <span>Manage Difficulty Level</span>
                    </div>
                </div>


                <div
                    className={context.active === "logout" ? "active-button" : "button"}
                    onClick={() => {
                        logout();
                    }}
                >
                    <div className="left">
                        <img src={logOut} alt="logOut" />
                        <span
                            style={{
                                color: "#F24242",
                            }}
                        >
                            Logout
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;