import React, { useState, useEffect } from "react";
import "./RightDepartment.css";
import axios from "axios";
import { toast } from "react-toastify";
import save from "../../assets/icons/save.svg";
import cancel from "../../assets/icons/cancel.svg";

const RightAddDepartment = () => {
  const [loading, setLoading] = useState(false);
  const [isEditActive, setIsEditActive] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  const [departmentLoading, setDepartmentLoading] = useState(false);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchText, setSearchText] = useState("");

  const [departmentDetails, setDepartmentDetails] = useState({
    department: "",
  });

  const token = localStorage.getItem("token");

  useEffect(() => {
    getDepartments();
    // eslint-disable-next-line
  }, []);

  const getDepartments = async () => {
    try {
      setDepartmentLoading(true);
      const response = await axios(
        "https://api.theeliteqa.com/api/department",
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.status === 200) {
        setDepartments(response.data.data);
        setTotalPage(Math.floor(response.data.data.length / 10) + 1);
        setLoading(false);
        setPages(departments.length / 10);
        setDepartmentLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createDepartment = async () => {
    try {
      setLoading(true);
      const body = {
        departmentDetails,
      };
      const response = await axios.post(
        "https://api.theeliteqa.com/api/department/create",
        body.departmentDetails,
        {
          headers: {
            token: token,
          },
        }
      );
      setDepartments((prev) => [response.data.data, ...prev]);
      toast.success("Department created successfully.");
      setDepartmentDetails({
        department: "",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to create department.");
      console.log(error.response.data);
    }
  };

  const updateDepartment = async () => {
    try {
      setLoading(true);
      const body = {
        departmentDetails,
      };

      const response = await axios.put(
        `https://api.theeliteqa.com/api/department/update/${departmentId}`,
        body.departmentDetails,
        {
          headers: {
            token: token,
          },
        }
      );

      toast.success("Department updated successfully.");
      setIsEditActive(false);
      setDepartmentDetails({
        department: "",
      });
      setLoading(false);
      let updatedData = departments.map((data) => {
        if (data._id === response.data.data._id) {
          data.department = response.data.data.department;
        }
        return data;
      });
      setDepartments(updatedData);
    } catch (error) {
      toast.error("Failed to update department.");
    }
  };

  const handleEdit = (data) => {
    setIsEditActive(true);
    setDepartmentId(data._id);
    setDepartmentDetails({
      department: data.department,
    });
  };

  const handleChange = (name) => (event) => {
    const value = event.target.value;
    setDepartmentDetails({ ...departmentDetails, [name]: value });
  };

  const deleteDepartment = async (id) => {
    try {
      await axios.delete(
        `https://api.theeliteqa.com/api/department/remove/${id}`,
        {
          headers: {
            token: token,
          },
        }
      );
      toast.success("Department deleted successfully.");
      let updatedData = departments.filter((data) => data._id !== id);
      setDepartments(updatedData);
    } catch (error) {
      toast.error("Failed to delete department.");
    }
  };

  const cancelEditing = () => {
    setDepartmentDetails({
      department: "",
    });
    setIsEditActive(false);
  };

  function goToNextPage() {
    // console.log(pageNum, "   ", totalPage)
    if (pageNum >= totalPage) {
      return toast.error("No more Pages");
    } else {
      setCurrentPage((page) => page + 1);
      setPageNum(pageNum + 1);
    }
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
    setPageNum((pageNum) => pageNum - 1);
  }

  // function changePage(event) {
  //     const pageNumber = Number(event.target.textContent);
  //     setCurrentPage(pageNumber);
  // }

  const getPaginatedData = () => {
    const startIndex = currentPage * 10 - 10;
    const endIndex = startIndex + 10;
    return departments.slice(startIndex, endIndex);
  };

  // const getPaginationGroup = () => {
  //     let start = Math.floor((currentPage - 1) / 5) * 5;
  //     return new Array(5).fill().map((_, idx) => start + idx + 1);
  // };

  const searchDepartments = async () => {
    try {
      setDepartmentLoading(true);
      const response = await axios.post(
        `https://api.theeliteqa.com/api/department/search`,
        { searchText: searchText },
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.data !== undefined) {
        setDepartments(response.data.data);
        setTotalPage(Math.floor(response.data.data.length / 10) + 1);
        setLoading(false);
        setPages(departments.length / 10);
        setDepartmentLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    if (e.target.value === "") {
      setPageNum(1);
      getDepartments();
    } else {
      setSearchText(e.target.value);
    }
  };

  return (
    <div className="add-department-container">
      <div className="add-topic-form-container">
        <div className="heading">
          <div className="title">
            <div className="sidebar"></div>
            <span>{isEditActive ? "Edit Department" : "Add Department"}</span>
          </div>

          <div className="form">
            <input
              type="text"
              placeholder="Enter department name"
              name="department"
              onChange={handleChange("department")}
              value={departmentDetails.department}
            />
            <button
              title={isEditActive ? "Update" : "Add"}
              onClick={() => {
                isEditActive ? updateDepartment() : createDepartment();
              }}
            >
              <img src={save} alt="save" />
              {loading ? (
                <i class="fa fa-circle-o-notch fa-spin"></i>
              ) : isEditActive ? (
                "Update"
              ) : (
                "Add"
              )}
            </button>

            {isEditActive ? (
              <button onClick={cancelEditing} title="Cancel">
                <img src={cancel} alt="save" />
                Cancel
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div className="list-topic">
        <div className="heading">
          <div className="title-group">
            <div className="sidebar"></div>
            <span>Added Departments</span>
          </div>
          <div className="search-container">
            <input
              type="search"
              placeholder="Search here"
              onChange={(e) => {
                handleSearch(e);
              }}
            />
            <button
              style={{
                width: "100px",
                height: "40px",
                background: "#384455",
                marginLeft: "20px",
                color: "white",
                cursor: "pointer",
                borderRadius: "2px",
              }}
              onClick={searchDepartments}
            >
              Search
            </button>
          </div>
          <div className="pagination">
            {/* previous button */}
            <button
              onClick={goToPreviousPage}
              // className={`prev ${currentPage === 1 ? 'disabled' : ''}`}
              disabled={pageNum <= 1 ? "true" : ""}
              title="Previous"
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.41421 6L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289L5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893C7.09763 0.683418 7.09763 1.31658 6.70711 1.70711L2.41421 6Z"
                  fill="white"
                />
              </svg>
            </button>

            {/* show page numbers */}
            {/* {getPaginationGroup().map((item, index) => (
                            <button
                                key={index}
                                onClick={changePage}
                                className={`paginationItem ${currentPage === item ? 'active' : null}`}
                            >
                                <span>{item}</span>
                            </button>
                        ))} */}
            <span> {pageNum + "/" + totalPage}</span>

            {/* next button */}
            <button
              onClick={goToNextPage}
              className={`next ${currentPage === pages ? "disabled" : ""}`}
              disabled={pageNum >= totalPage ? "true" : ""}
              title="Next"
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
        {departmentLoading ? (
          <div className="loading-container">
            <i
              class="fa fa-circle-o-notch fa-spin fa-5x"
              style={{
                color: "#FF6813",
              }}
            ></i>
          </div>
        ) : (
          <div className="topic-container">
            <table>
              <tr>
                <th>Sr.No</th>
                <th>Department</th>
                <th>Options</th>
              </tr>

              {getPaginatedData()?.map((data, index) => {
                return (
                  <tr>
                    <td>{index + 1 + 10 * (pageNum - 1)}</td>
                    <td>{data.department}</td>
                    <td>
                      <div className="options">
                        {/* Edit Icon */}
                        <div
                          className="editIconCircle"
                          title="Edit"
                          onClick={() => handleEdit(data)}
                        >
                          <svg
                            className="editIcon"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_1351_1043)">
                              <path
                                d="M9.16666 3.33334H3.33332C2.8913 3.33334 2.46737 3.50893 2.15481 3.82149C1.84225 4.13405 1.66666 4.55798 1.66666 5V16.6667C1.66666 17.1087 1.84225 17.5326 2.15481 17.8452C2.46737 18.1577 2.8913 18.3333 3.33332 18.3333H15C15.442 18.3333 15.8659 18.1577 16.1785 17.8452C16.4911 17.5326 16.6667 17.1087 16.6667 16.6667V10.8333"
                                stroke="blue"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M15.4167 2.08333C15.7482 1.75181 16.1978 1.56557 16.6667 1.56557C17.1355 1.56557 17.5851 1.75181 17.9167 2.08333C18.2482 2.41485 18.4344 2.86449 18.4344 3.33333C18.4344 3.80217 18.2482 4.25181 17.9167 4.58333L9.99999 12.5L6.66666 13.3333L7.49999 10L15.4167 2.08333Z"
                                stroke="blue"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1351_1043">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>

                        {/* Delete Icon */}
                        <div
                          className="deleteIconCircle"
                          title="Delete"
                          onClick={() => deleteDepartment(data._id)}
                        >
                          <svg
                            className="deleteIcon"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.5 5H4.16667H17.5"
                              stroke="red"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M15.8333 5V16.6667C15.8333 17.1087 15.6577 17.5326 15.3451 17.8452C15.0326 18.1577 14.6087 18.3333 14.1666 18.3333H5.83329C5.39127 18.3333 4.96734 18.1577 4.65478 17.8452C4.34222 17.5326 4.16663 17.1087 4.16663 16.6667V5M6.66663 5V3.33333C6.66663 2.89131 6.84222 2.46738 7.15478 2.15482C7.46734 1.84226 7.89127 1.66667 8.33329 1.66667H11.6666C12.1087 1.66667 12.5326 1.84226 12.8451 2.15482C13.1577 2.46738 13.3333 2.89131 13.3333 3.33333V5"
                              stroke="red"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.33337 9.16667V14.1667"
                              stroke="red"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.6666 9.16667V14.1667"
                              stroke="red"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default RightAddDepartment;
