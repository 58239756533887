import React, { useState, useEffect, useContext, useRef } from "react";
import "./add_validity.css";
import DataContext from "../../store/DataProvider";
import axios from "axios";
import { toast } from "react-toastify";
import EditValidityPopup from "../edit_validity_popup/EditValidity";

const RightAddValidity = () => {
  const context = useContext(DataContext);
  const scroll = useRef(null);
  const [loading, setLoading] = useState(false);
  const [validity, setValidity] = useState([]);
  const [clients, setClients] = useState([]); // eslint-disable-line
  const [packages, setpackages] = useState([]); // eslint-disable-line
  const [payment, setpayment] = useState([]); // eslint-disable-line
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [isEditActive, setIsEditActive] = useState(false);

  const [createLoading, setcreateLoading] = useState(false); // eslint-disable-line
  const [validityID, setvalidityID] = useState(""); // eslint-disable-line

  const [validityDetails, setvalidityDetails] = useState({
    // eslint-disable-line
    clientId: "",
    packageId: "",
    paymentId: "",
    startDate: "",
    numberOfDays: "",
    numberOfInvites: "",
    numberOfDates: "",
  });
  const [searchText, setSearchText] = useState("");

  const token = localStorage.getItem("token");

  useEffect(() => {
    getClients();
    getPackages();
    getPayments();
    getValidity();
  }, [context.changeEditActive === "client-edit"]); // eslint-disable-line react-hooks/exhaustive-deps

  const getValidity = async () => {
    try {
      setLoading(true);
      const response = await axios("https://api.theeliteqa.com/api/validity/", {
        headers: {
          token: token,
        },
      });
      setValidity(response.data.data);
      setTotalPage(Math.floor(response.data.data.length / 10) + 1);

      setLoading(false);
      setPages(validity.length / 10);
    } catch (error) {
      setLoading(false);
    }
  };

  const getClients = async () => {
    try {
      const response = await axios("https://api.theeliteqa.com/api/client/", {
        headers: {
          token: token,
        },
      });
      setClients(response.data.data);
    } catch (error) {
      toast.error("Failed to get client's");
    }
  };

  const getPackages = async () => {
    try {
      const response = await axios("https://api.theeliteqa.com/api/package/", {
        headers: {
          token: token,
        },
      });
      setpackages(response.data.data);
    } catch (error) {
      toast.error("Failed to get packages");
    }
  };

  const getPayments = async () => {
    try {
      const response = await axios("https://api.theeliteqa.com/api/payment/", {
        headers: {
          token: token,
        },
      });
      setpayment(response.data.data);
    } catch (error) {
      toast.error("Failed to get payment");
    }
  };

  const handleChange = (name) => (event) => {
    const value = event.target.value;
    setvalidityDetails({ ...validityDetails, [name]: value });
  };

  const formatDate = (date) => {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const handleEdit = (data) => {
    //eslint-disable-line
    setIsEditActive(true);
    scroll.current.scrollTo(0, 0);
    setvalidityDetails({
      clientId: data.clientId?._id,
      packageId: data.packageId?._id,
      paymentId: data.paymentId._id,
      startDate: data.startDate,
      numberOfDays: data.numberOfDays,
      numberOfInvites: data.numberOfInvites,
      numberOfDates: data.numberOfDates,
    });
    setvalidityID(data._id);
  };

  const deleteValidity = async (id) => {
    try {
      await axios.delete(
        `https://api.theeliteqa.com/api/validity/remove/${id}`,
        {
          headers: {
            token: token,
          },
        }
      );
      toast.success("Package deleted successfully.");
      const updatedData = validity.filter((data) => {
        return data._id !== id;
      });
      setValidity(updatedData);
    } catch (error) {
      toast.error("Failed to delete package.");
    }
  };

  const cancelEdit = () => {
    setIsEditActive(false);
    setvalidityDetails({
      clientId: "",
      packageId: "",
      paymentId: "",
      startDate: "",
      numberOfDays: "",
      numberOfInvites: "",
      numberOfDates: "",
    });
    setvalidityID("");
  };

  const updateValidity = async () => {
    try {
      setcreateLoading(true);
      const body = {
        validityDetails,
      };
      await axios.patch(
        `https://api.theeliteqa.com/api/validity/update/${validityID}`,
        body.validityDetails,
        {
          headers: {
            token: token,
          },
        }
      );
      toast.success("Validity updated successfully.");
      context.changeEditActive("");
      setvalidityDetails({
        clientId: "",
        packageId: "",
        paymentId: "",
        startDate: "",
        numberOfDays: "",
        numberOfInvites: "",
        numberOfDates: "",
      });
      getValidity();
      setvalidityID("");
      setcreateLoading(false);
      setIsEditActive(false);
    } catch (error) {
      setcreateLoading(false);
      toast.error("Failed to update client.");
    }
  };

  function goToNextPage() {
    // console.log(pageNum, "   ", totalPage)
    if (pageNum >= totalPage) {
      return toast.error("No more Pages");
    } else {
      setCurrentPage((page) => page + 1);
      setPageNum(pageNum + 1);
    }
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
    setPageNum((pageNum) => pageNum - 1);
  }

  // function changePage(event) {
  //     const pageNumber = Number(event.target.textContent);
  //     setCurrentPage(pageNumber);
  // }

  const getPaginatedData = () => {
    const startIndex = currentPage * 10 - 10;
    const endIndex = startIndex + 10;
    return validity.slice(startIndex, endIndex);
  };

  // const getPaginationGroup = () => {
  //     let start = Math.floor((currentPage - 1) / 5) * 5;
  //     return new Array(5).fill().map((_, idx) => start + idx + 1);
  // };

  const searchValidity = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `https://api.theeliteqa.com/api/validity/search`,
        { searchText: searchText },
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.data !== undefined) {
        if (response.data.data.length === 0) {
          toast.error("There is no result for this search.");
          setLoading(false);
        } else {
          setValidity(response.data.data);
          setTotalPage(Math.floor(response.data.data.length / 10) + 1);
          setLoading(false);
          setPages(validity.length / 10);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    if (e.target.value === "") {
      setPageNum(1);
      getValidity();
    } else {
      setSearchText(e.target.value);
    }
  };

  return (
    <div className="validity-container" ref={scroll}>
      {/* <div className='form-container1'>
                <div className='heading'>
                    <div className='title'>
                        <div className="sidebar"></div>
                        <span>Search Container</span>
                    </div>
                    <div className="buttons-container">
                        <button className='save' onClick={() => context.editActive === "client-edit" ? updateClient() : createValidity()}>{createLoading ? "Loading ..." : context.editActive === "client-edit" ? "Update" : "Save"}</button>
                        {context.editActive === "client-edit" ? <button onClick={cancelEdit} className='cancel'>Cancel</button> : null}
                    </div>
                </div>

                <div className='form'>
                    <div className='input-container'>
                        <label>Client *</label>
                        <select value={validityDetails.clientId} onChange={handleChange("clientId")} >
                            <option value="">Select Client</option>
                            {
                                clients.map(data => {
                                    return <option key={data._id} value={data._id}>{data.companyName}</option>
                                })
                            }
                        </select>
                    </div>

                    <div className='input-container'>
                        <label>Package *</label>
                        <select value={validityDetails.packageId} onChange={handleChange("packageId")}>
                            <option value="">Select Package</option>
                            {
                                packages.map(data => {
                                    return <option key={data._id} value={data._id}>{data.name}</option>
                                })
                            }
                        </select>
                    </div>

                    <div className='input-container'>
                        <label>Payment Id *</label>
                        <select value={validityDetails.paymentId} onChange={handleChange("paymentId")}>
                            <option value="">Select Payment Id</option>
                            {
                                payment.map(data => {
                                    return <option key={data._id} value={data._id}>{data._id}</option>
                                })
                            }
                        </select>
                    </div>

                    <div className='input-container'>
                        <label>Start Date *</label>
                        <input type="date" onChange={handleChange("startDate")} value={context.editActive === "client-edit" ? formatDate(validityDetails.startDate) : validityDetails.startDate} />
                    </div>

                    <div className='input-container'>
                        <label>No. of Days *</label>
                        <input type="number" placeholder="Enter number of Days" maxlength="10"
                            onChange={handleChange("numberOfDays")}
                            value={validityDetails.numberOfDays} />
                    </div>

                    <div className='input-container'>
                        <label>No. of Invites *</label>
                        <input type="text" placeholder="Enter number of Invites" maxlength="10"
                            onChange={handleChange("numberOfInvites")}
                            value={validityDetails.numberOfInvites} />
                    </div>

                    <div className='input-container'>
                        <label>No. of Dates *</label>
                        <input type="text" placeholder="Enter number of Dates" maxlength="10"
                            onChange={handleChange("numberOfDates")}
                            value={validityDetails.numberOfDates} />
                    </div>
                </div>
            </div> */}

      <div className="view-container-validity">
        <div
          className="heading"
          style={{
            padding: 24,
            borderTop: "5px #FF6812 solid",
            borderRadius: "2px 2px 0px 0px",
          }}
        >
          <div className="title">
            <div className="sidebar"></div>
            <span>View Validity</span>
          </div>
          <div className="search-container">
            <input
              type="search"
              placeholder="Search here"
              onChange={(e) => {
                handleSearch(e);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  searchValidity();
                }
              }}
            />
            <button
              style={{
                width: "100px",
                height: "40px",
                background: "#384455",
                marginLeft: "20px",
                color: "white",
                cursor: "pointer",
                borderRadius: "2px",
              }}
              onClick={searchValidity}
            >
              Search
            </button>
          </div>
          <div className="pagination">
            {/* previous button */}
            <button
              onClick={goToPreviousPage}
              // className={`prev ${currentPage === 1 ? 'disabled' : ''}`}
              disabled={pageNum <= 1 ? "true" : ""}
              title="Previous"
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.41421 6L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289L5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893C7.09763 0.683418 7.09763 1.31658 6.70711 1.70711L2.41421 6Z"
                  fill="white"
                />
              </svg>
            </button>

            {/* show page numbers */}
            {/* {getPaginationGroup().map((item, index) => (
                            <button
                                key={index}
                                onClick={changePage}
                                className={`paginationItem ${currentPage === item ? 'active' : null}`}
                            >
                                <span>{item}</span>
                            </button>
                        ))} */}
            <span> {pageNum + "/" + totalPage}</span>

            {/* next button */}
            <button
              onClick={goToNextPage}
              className={`next ${currentPage === pages ? "disabled" : ""}`}
              disabled={pageNum >= totalPage ? "true" : ""}
              title="Next"
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="view">
          {loading ? (
            <div className="loading-container">
              <i
                class="fa fa-circle-o-notch fa-spin fa-5x"
                style={{ color: "#FF6813" }}
              />
            </div>
          ) : (
            <table>
              <tr>
                <th>Sr.No</th>
                <th>Client</th>
                <th>PackageID</th>
                <th>PaymentID</th>
                <th>Start Date</th>
                <th>No.of Days</th>
                <th>No.of Invites</th>
                <th>No.of Dates</th>
                <th>Options</th>
              </tr>
              {/* {
                                validity.map((data, index) => {
                                    return <tr key={data._id}>
                                        <td>{index + 1}</td>
                                        <td>{data.clientId?.companyName}</td>
                                        <td>{data.packageId?.name}</td>
                                        <td>{data.paymentId?.transactionId}</td>
                                        <td>{formatDate(data.startDate)}</td>
                                        <td>{data.numberOfDays}</td>
                                        <td>{data.numberOfInvites}</td>
                                        <td>{data.numberOfDates}</td>
                                        <td>
                                            <div className="options">
                                                <div className='editIconCircle' title='Edit' onClick={() => handleEdit(data)}>
                                                    <svg className="editIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_1351_1043)">
                                                            <path d="M9.16666 3.33334H3.33332C2.8913 3.33334 2.46737 3.50893 2.15481 3.82149C1.84225 4.13405 1.66666 4.55798 1.66666 5V16.6667C1.66666 17.1087 1.84225 17.5326 2.15481 17.8452C2.46737 18.1577 2.8913 18.3333 3.33332 18.3333H15C15.442 18.3333 15.8659 18.1577 16.1785 17.8452C16.4911 17.5326 16.6667 17.1087 16.6667 16.6667V10.8333" stroke="blue" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M15.4167 2.08333C15.7482 1.75181 16.1978 1.56557 16.6667 1.56557C17.1355 1.56557 17.5851 1.75181 17.9167 2.08333C18.2482 2.41485 18.4344 2.86449 18.4344 3.33333C18.4344 3.80217 18.2482 4.25181 17.9167 4.58333L9.99999 12.5L6.66666 13.3333L7.49999 10L15.4167 2.08333Z" stroke="blue" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_1351_1043">
                                                                <rect width="20" height="20" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </div>


                                                <div className='deleteIconCircle' title='Delete' onClick={() => deleteValidity(data._id)}>
                                                    <svg className='deleteIcon' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.5 5H4.16667H17.5" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M15.8333 5V16.6667C15.8333 17.1087 15.6577 17.5326 15.3451 17.8452C15.0326 18.1577 14.6087 18.3333 14.1666 18.3333H5.83329C5.39127 18.3333 4.96734 18.1577 4.65478 17.8452C4.34222 17.5326 4.16663 17.1087 4.16663 16.6667V5M6.66663 5V3.33333C6.66663 2.89131 6.84222 2.46738 7.15478 2.15482C7.46734 1.84226 7.89127 1.66667 8.33329 1.66667H11.6666C12.1087 1.66667 12.5326 1.84226 12.8451 2.15482C13.1577 2.46738 13.3333 2.89131 13.3333 3.33333V5" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M8.33337 9.16667V14.1667" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M11.6666 9.16667V14.1667" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                })
                            } */}

              {getPaginatedData()?.map((data, index) => {
                return (
                  <tr key={data._id}>
                    <td>{index + 1}</td>
                    <td>
                      {data.clientId?.companyName == null
                        ? "-"
                        : data.clientId?.companyName}
                    </td>
                    <td>
                      {data.packageId?.name == null
                        ? "-"
                        : data.packageId?.name}
                    </td>
                    <td>
                      {data.paymentId?.transactionId == null
                        ? "-"
                        : data.paymentId?.transactionId}
                    </td>
                    <td>
                      {formatDate(data.startDate) == null
                        ? "-"
                        : formatDate(data.startDate)}
                    </td>
                    <td>
                      {data.numberOfDays == null ? "-" : data.numberOfDays}
                    </td>
                    <td>
                      {data.numberOfInvites == null
                        ? "-"
                        : data.numberOfInvites}
                    </td>
                    <td>
                      {data.numberOfDates == null ? "-" : data.numberOfDates}
                    </td>
                    <td>
                      <div className="options">
                        <div
                          className="editIconCircle"
                          title="Edit"
                          onClick={() => handleEdit(data)}
                        >
                          <svg
                            className="editIcon"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_1351_1043)">
                              <path
                                d="M9.16666 3.33334H3.33332C2.8913 3.33334 2.46737 3.50893 2.15481 3.82149C1.84225 4.13405 1.66666 4.55798 1.66666 5V16.6667C1.66666 17.1087 1.84225 17.5326 2.15481 17.8452C2.46737 18.1577 2.8913 18.3333 3.33332 18.3333H15C15.442 18.3333 15.8659 18.1577 16.1785 17.8452C16.4911 17.5326 16.6667 17.1087 16.6667 16.6667V10.8333"
                                stroke="blue"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M15.4167 2.08333C15.7482 1.75181 16.1978 1.56557 16.6667 1.56557C17.1355 1.56557 17.5851 1.75181 17.9167 2.08333C18.2482 2.41485 18.4344 2.86449 18.4344 3.33333C18.4344 3.80217 18.2482 4.25181 17.9167 4.58333L9.99999 12.5L6.66666 13.3333L7.49999 10L15.4167 2.08333Z"
                                stroke="blue"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1351_1043">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>

                        <div
                          className="deleteIconCircle"
                          title="Delete"
                          onClick={() => deleteValidity(data._id)}
                        >
                          <svg
                            className="deleteIcon"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.5 5H4.16667H17.5"
                              stroke="red"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M15.8333 5V16.6667C15.8333 17.1087 15.6577 17.5326 15.3451 17.8452C15.0326 18.1577 14.6087 18.3333 14.1666 18.3333H5.83329C5.39127 18.3333 4.96734 18.1577 4.65478 17.8452C4.34222 17.5326 4.16663 17.1087 4.16663 16.6667V5M6.66663 5V3.33333C6.66663 2.89131 6.84222 2.46738 7.15478 2.15482C7.46734 1.84226 7.89127 1.66667 8.33329 1.66667H11.6666C12.1087 1.66667 12.5326 1.84226 12.8451 2.15482C13.1577 2.46738 13.3333 2.89131 13.3333 3.33333V5"
                              stroke="red"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8.33337 9.16667V14.1667"
                              stroke="red"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.6666 9.16667V14.1667"
                              stroke="red"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </table>
          )}
        </div>
      </div>
      {isEditActive ? (
        <EditValidityPopup
          validity={validityDetails}
          cancelEdit={cancelEdit}
          updateValidity={updateValidity}
          handleChange={handleChange}
          clients={clients}
          packages={packages}
          loading={createLoading}
        />
      ) : null}
    </div>
  );
};

export default RightAddValidity;
