import React, { useState, useEffect } from "react";
import "./right_dashboard.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import { toast } from "react-toastify";
import trash from "../../assets/icons/trash.svg";

const RightDashboard = () => {
  const [value, onChange] = useState(new Date());
  const [todoList, settodoList] = useState([]);
  const [todoLoading, settodoLoading] = useState(false);

  const [createLoading, setcreateLoading] = useState(false);
  const [task, settask] = useState("");

  const [taskStateChanged, settaskStateChanged] = useState(null);
  const [userStatsLoading, setuserStatsLoading] = useState(false);
  const [userStats, setuserStats] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    getToDoList();
    getUserStats();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getUserStats = async () => {
    try {
      setuserStatsLoading(true);
      const res = await axios.get(
        "https://api.theeliteqa.com/api/adminDashboard/getAdminDashboardData",
        {
          headers: {
            token: token,
          },
        }
      );
      setuserStats(res.data);
      setuserStatsLoading(false);
    } catch (error) {
      setuserStatsLoading(false);
      toast.error("Failed to get user stats");
    }
  };

  const getToDoList = async () => {
    try {
      settodoLoading(true);
      const res = await axios.get("https://api.theeliteqa.com/api/todo/", {
        headers: {
          token: token,
        },
      });
      settodoList(res.data.data);
      settodoLoading(false);
    } catch (error) {
      settodoLoading(false);
      toast.error("Failed to get Todo List");
    }
  };

  const addItemToCompleted = async (data) => {
    try {
      settaskStateChanged(data._id);
      let completedTime = new Date();
      await axios.put(
        `https://api.theeliteqa.com/api/todo/update/${data._id}`,
        {
          content: data.content,
          createdAt: data.createdAt,
          completedAt: completedTime,
        },
        {
          headers: {
            token: token,
          },
        }
      );

      let updatedData = todoList.map((item) => {
        if (item._id === data._id) {
          item.completedAt = completedTime;
        }
        return item;
      });
      settodoList(updatedData);
      settaskStateChanged(null);
      toast.success("Task added to completed list.");
    } catch (error) {
      toast.error("Failed to add task in complete.");
      settaskStateChanged(null);
    }
  };

  const removeItemFromCompleted = async (data) => {
    try {
      settaskStateChanged(data._id);
      await axios.put(
        `https://api.theeliteqa.com/api/todo/update/${data._id}`,
        {
          content: data.content,
          createdAt: data.createdAt,
          completedAt: null,
        },
        {
          headers: {
            token: token,
          },
        }
      );
      let updatedData = todoList.map((item) => {
        if (item._id === data._id) {
          item.completedAt = null;
        }
        return item;
      });
      settodoList(updatedData);
      settaskStateChanged(null);
      toast.success("Task removed from completed list.");
    } catch (error) {
      toast.error("Failed to remove task.");
      settaskStateChanged(null);
    }
  };

  const createTodo = async () => {
    try {
      if (task.trim() === "") return toast.error("Please add task to create");
      setcreateLoading(true);
      const res = await axios.post(
        "https://api.theeliteqa.com/api/todo/create",
        {
          content: task,
          createdAt: new Date(),
          completedAt: null,
        },
        {
          headers: {
            token: token,
          },
        }
      );
      settodoList((prev) => [res.data.data, ...prev]);
      setcreateLoading(false);
      settask("");
      toast.success("Task created successfully");
    } catch (error) {
      console.log(error);
      setcreateLoading(false);
      toast.error("Failed to create task");
    }
  };

  const deleteEvent = async (id) => {
    try {
      settodoLoading(true);
      await axios.delete(`https://api.theeliteqa.com/api/todo/delete/${id}`, {
        headers: {
          token: token,
        },
      });
      toast.success("Task deleted successfully.");
      let updatedData = todoList.filter((data) => data._id !== id);
      settodoList(updatedData);
      settodoLoading(false);
    } catch (error) {
      settodoLoading(false);
      toast.error("Failed to delete Task");
    }
  };

  return (
    <div className="right-dashboard-container">
      <div className="top-card-container">
        <div className="card">
          <span className="content-one">Clients</span>
          <span className="content-two">
            {userStatsLoading ? (
              <i
                className="fa fa-circle-o-notch fa-spin fa-1x"
                style={{ color: "#FF6813" }}
              />
            ) : (
              userStats?.clients
            )}
          </span>
          <span className="content-three">Total number of clients</span>
        </div>
        <div className="card">
          <span className="content-one">Users</span>
          <span className="content-two">
            {userStatsLoading ? (
              <i
                className="fa fa-circle-o-notch fa-spin fa-1x"
                style={{ color: "#FF6813" }}
              />
            ) : (
              userStats?.users
            )}
          </span>
          <span className="content-three">Total number of users</span>
        </div>
        <div className="card">
          <span className="content-one">Invites</span>
          <span className="content-two">
            {userStatsLoading ? (
              <i
                className="fa fa-circle-o-notch fa-spin fa-1x"
                style={{ color: "#FF6813" }}
              />
            ) : (
              userStats?.invites
            )}
          </span>
          <span className="content-three">Total number of invites</span>
        </div>
        <div className="card">
          <span className="content-one">Tests</span>
          <span className="content-two">
            {userStatsLoading ? (
              <i
                className="fa fa-circle-o-notch fa-spin fa-1x"
                style={{ color: "#FF6813" }}
              />
            ) : (
              userStats?.tests
            )}
          </span>
          <span className="content-three">Total number of tests</span>
        </div>
      </div>

      <div className="bottom-cards-container">
        <div className="todo-list-container">
          <div className="heading">
            <div className="sidebar"></div>
            <span>TODO List</span>
          </div>
          <span className="sub-heading">
            Enter your daily tasks here to keep track with everything
          </span>
          <div className="add-todo-container">
            <input
              value={task}
              type="text"
              placeholder="Your Task Goes Here"
              onChange={(e) => settask(e.target.value)}
            />
            <button title="Add Task" onClick={createTodo}>
              {createLoading ? (
                <i
                  className="fa fa-circle-o-notch fa-spin fa-1x"
                  style={{ color: "#FFFFFF" }}
                />
              ) : (
                "+"
              )}
            </button>
          </div>

          {todoLoading ? (
            <div className="loading-container">
              {" "}
              <i
                className="fa fa-circle-o-notch fa-spin fa-5x"
                style={{ color: "#FF6813" }}
              ></i>{" "}
            </div>
          ) : (
            <React.Fragment>
              <div className="todo-container">
                {todoList.map((data) => {
                  if (data.completedAt !== null) return null;
                  return (
                    <div className="todo" key={data._id}>
                      {taskStateChanged === data._id ? (
                        <i
                          className="fa fa-circle-o-notch fa-spin fa-2x icon-center"
                          style={{
                            color: "#FF6813",
                            width: 24,
                            height: 24,
                            marginRight: 12,
                          }}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          onClick={() => addItemToCompleted(data)}
                        />
                      )}
                      <span>{data.content}</span>
                    </div>
                  );
                })}
              </div>
              <div className="completed-todo-container">
                {todoList.map((data) => {
                  if (data.completedAt === null) return null;
                  return (
                    <div className="todo" key={data._id}>
                      <div className="group">
                        {taskStateChanged === data._id ? (
                          <i
                            className="fa fa-circle-o-notch fa-spin fa-2x icon-center"
                            style={{
                              color: "#FF6813",
                              width: 24,
                              height: 24,
                              marginRight: 12,
                            }}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            checked
                            readOnly={true}
                            onClick={() => removeItemFromCompleted(data)}
                          />
                        )}
                        <span>{data.content}</span>
                      </div>
                      <div
                        title="Delete"
                        className="delete"
                        onClick={() => deleteEvent(data._id)}
                      >
                        <img src={trash} alt="trash" />
                      </div>
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="calender-container">
          <Calendar onChange={onChange} value={value} />
        </div>
      </div>
    </div>
  );
};

export default RightDashboard;
