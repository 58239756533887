import { createContext, useState } from 'react';

const DataContext = createContext({
    active: 'home',
    clientId: '',
    editActive: '',
    clientData: null,
    orderId: '',
    orderData: null,
    clientUserId: '',
    clientUserData: null,


    changeActive: () => { },
    addClientId: () => { },
    changeEditActive: () => { },
    addClientData: () => { },
    addOrderId: () => { },
    addOrderData: () => { },
    addClientUserId: () => { },
    addClientUserData: () => { },
});


export const DataContextProvider = (props) => {
    const [active, setActive] = useState('home');
    const [clientId, setClientId] = useState('');
    const [editActive, setEditActive] = useState('');
    const [clientData, setClientData] = useState(null);
    const [orderId, setOrderId] = useState('');
    const [orderData, setOrderData] = useState(null);
    const [eventId, seteventId] = useState('')
    const [eventData, seteventData] = useState(null)
    const [clientUserId, setClientUserId] = useState('')
    const [clientUserData, setClientUserData] = useState(null)


    const changeActive = (value) => {
        setActive(value);
    };

    const addClientId = (value) => {
        setClientId(value);
    };

    const addEventId = (value) => {
        seteventId(value);
    };

    const changeEditActive = (value) => {
        setEditActive(value);
    };

    const addClientData = (value) => {
        setClientData(value);
    };

    const addEventData = (value) => {
        seteventData(value);
    };

    const addOrderId = (value) => {
        setOrderId(value);
    };

    const addOrderData = (value) => {
        setOrderData(value);
    };

    const addClientUserId = (value) => {
        setClientUserId(value)
    }
    
    const addClientUserData = (value) => {
        setClientUserData(value);
    };

    const context = {
        // states 
        active: active,
        clientId: clientId,
        editActive: editActive,
        clientData: clientData,
        orderId: orderId,
        orderData: orderData,
        eventData: eventData,
        eventId: eventId,
        clientUserData: clientUserData,
        clientUserId: clientUserId,

        // functions
        changeActive: changeActive,
        addClientId: addClientId,
        changeEditActive: changeEditActive,
        addClientData: addClientData,
        addOrderId: addOrderId,
        addOrderData: addOrderData,
        addEventId: addEventId,
        addEventData: addEventData,
        addClientUserData: addClientUserData,
        addClientUserId: addClientUserId
    };


    return (
        <DataContext.Provider value={context}>{props.children}</DataContext.Provider>
    );
};

export default DataContext;