import React, { useState, useEffect, useContext } from 'react';
import "./RightViewEvent.css";
import trash from "../../assets/icons/trash.svg";
import axios from 'axios';
import { toast } from "react-toastify";
import DataContext from '../../store/DataProvider';
import close from "../../assets/icons/close.svg";
// import avatar from "../../assets/icons/Avatar.svg";
import view from "../../assets/icons/View.svg";
import edit from "../../assets/icons/edit.svg";


const RightViewEvent = () => {
    const context = useContext(DataContext);
    const [loading, setLoading] = useState(false);
    const [isViewActive, setIsViewActive] = useState(false);
    const [events, setevents] = useState([]);
    const [viewData, setViewData] = useState(null);
    //for pagination
    const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNum, setPageNum] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const token = localStorage.getItem("token");

    useEffect(() => {
        getEvents();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getEvents = async () => {
        try {
            setLoading(true);
            const response = await axios('https://api.theeliteqa.com/api/event/getAllEvent', {
                headers: {
                    token: token
                }
            });
            if (response.data !== undefined) {
                setevents(response.data);
                setTotalPage(Math.floor(response.data.length / 10) + 1);
                setPages(events.length / 10);
                // console.log(response.data)
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const deleteEvent = async (id) => {
        try {
            setLoading(true);
            await axios.delete(`https://api.theeliteqa.com/api/event/deleteEvent/${id}`, {
                headers: {
                    token: token
                }
            });
            toast.success('Event deleted successfully.');
            let updatedData = events.filter(data => data._id !== id);
            setevents(updatedData);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error('Failed to delete client.');
        }
    };

    const handleView = (data) => {
        setIsViewActive(true);
        setViewData(data);
    };

    const handleEditBtn = (data) => {
        context.changeEditActive('event-edit');
        context.addEventId(data._id);
        context.addEventData(data);
        context.changeActive('onboardevents');
    };


    const formatDate = (date) => {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    };


    function goToNextPage() {
        // console.log(pageNum, "   ", totalPage)
        if (pageNum >= totalPage) {
            return toast.error('No more Pages');
        }
        else {
            setCurrentPage((page) => page + 1);
            setPageNum(pageNum + 1);
        }
    }

    function goToPreviousPage() {

        setCurrentPage((page) => page - 1);
        setPageNum((pageNum) => pageNum - 1);

    }

    // function changePage(event) {
    //     const pageNumber = Number(event.target.textContent);
    //     setCurrentPage(pageNumber);
    // }

    const getPaginatedData = () => {
        const startIndex = currentPage * 10 - 10;
        const endIndex = startIndex + 10;
        return events.slice(startIndex, endIndex);
    };

    // const getPaginationGroup = () => {
    //     let start = Math.floor((currentPage - 1) / 5) * 5;
    //     return new Array(5).fill().map((_, idx) => start + idx + 1);
    // };


    const searchEvent = async () => {
        try {
            setLoading(true);
            const response = await axios.post(`https://api.theeliteqa.com/api/event/search`, { searchText: searchText }, {
                headers: {
                    token: token,
                }
            });
            if (response.data !== undefined) {
                if (response.data.data.length === 0) {
                    toast.error("There is no result for this search.");
                    setLoading(false);
                } else {
                    setevents(response.data.data);
                    setTotalPage(Math.floor(response.data.data.length / 10) + 1);
                    setPages(events.length / 10);
                    setLoading(false);
                }
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const handleSearch = (e) => {
        if (e.target.value === "") {
            setPageNum(1);
            getEvents();
        } else {
            setSearchText(e.target.value);
        }
    };

    const veiwEventPopup = () => {
        return (
            <div className="view-event-container">
                <div className='card-container'>
                    <div className="heading1">
                        <div className="left-side">
                            <div className="sidebar"></div>
                            <span>View Event</span>
                        </div>
                        <img src={close} alt="close" onClick={() => {
                            setIsViewActive(false);
                            setViewData(null);
                        }} />
                    </div>

                    <div className="event-data-container">
                        <div className='four-col-grid'>
                            <div className="input-image">
                                <span>Image</span>
                                <div className="data">
                                    {viewData?.image !== "" & viewData?.image !== "No Image" ? <img src={viewData?.image} alt="event" /> : null}
                                </div>
                            </div>
                            <div className="input">
                                <span>Title</span>
                                <div className="data">
                                    <span>{viewData?.title}</span>
                                </div>
                            </div>
                            <div className="input">
                                <span>Date</span>
                                <div className="data">
                                    <span>{formatDate(viewData?.Date)}</span>
                                </div>
                            </div>
                            <div className="input">
                                <span>Time</span>
                                <div className="data">
                                    <span>{viewData?.timings}</span>
                                </div>
                            </div>
                        </div>

                        <div className='two-col-grid'>
                            <div className="input">
                                <span>Description</span>
                                <div className="data">
                                    <span>{viewData?.description}</span>
                                </div>
                            </div>
                            <div className="input why">
                                <span>Why This Event</span>
                                <div className="data">
                                    <span>{viewData?.whyThisEvent}</span>
                                </div>
                            </div>

                        </div>
                    </div>

                    <span className='speaker-heading'>Speaker</span>
                    <div className="speaker-container">
                        {viewData.speaker?.map((data, index) => {
                            return (
                                <div className="contact-person-card" key={index}>
                                    <img src={data?.image} alt="avatar" />
                                    <span className='name'>{data?.name}</span>
                                    <span>{data?.designation}</span>
                                    <span>{data?.linkedIn}</span>
                                </div>
                            );
                        })}
                    </div>
                </div >
            </div>
        );
    };



    return (
        <div className='right-view-event-container'>
            <div className="list-events">
                <div className="heading">
                    <div className="title-group">
                        <div className="sidebar"></div>
                        <span>View Events</span>
                    </div>
                    <div className="search-container">
                        <input type="search" placeholder='Search here' onChange={(e) => {
                            handleSearch(e);
                        }} onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                searchEvent();
                            }
                        }} />
                        <button style={{
                            width: "100px",
                            height: "40px",
                            background: "#384455",
                            marginLeft: "20px",
                            color: "white",
                            cursor: "pointer",
                            borderRadius: "2px"
                        }} onClick={searchEvent}>Search</button>
                    </div>
                    <div className="pagination">
                        {/* previous button */}
                        <button
                            onClick={goToPreviousPage}
                            // className={`prev ${currentPage === 1 ? 'disabled' : ''}`}
                            disabled={pageNum <= 1 ? "true" : ""}
                            title="Previous"
                        >
                            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.41421 6L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289L5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893C7.09763 0.683418 7.09763 1.31658 6.70711 1.70711L2.41421 6Z" fill="white" />
                            </svg>
                        </button>

                        {/* show page numbers */}
                        {/* {getPaginationGroup().map((item, index) => (
                            <button
                                key={index}
                                onClick={changePage}
                                className={`paginationItem ${currentPage === item ? 'active' : null}`}
                            >
                                <span>{item}</span>
                            </button>
                        ))} */}
                        <span> {pageNum + "/" + totalPage}</span>

                        {/* next button */}
                        <button
                            onClick={goToNextPage}
                            className={`next ${currentPage === pages ? 'disabled' : ''}`}
                            disabled={pageNum >= totalPage ? "true" : ""}
                            title="Next"
                        >
                            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z" fill="white" />
                            </svg>
                        </button>
                    </div>
                </div>
                {loading ? <div className='loading-container'>
                    <i className="fa fa-circle-o-notch fa-spin fa-5x" style={{ color: "#FF6813" }}></i>
                </div> : <div className="staff-container">
                    <table>
                        <tr>
                            <th>Sr.No</th>
                            <th>Title</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Description</th>
                            <th>Why This Event</th>
                            <th>Options</th>
                        </tr>

                        {getPaginatedData()?.map((data, index) => {
                            return (
                                <tr>
                                    <td>{index + 1 + 10 * (pageNum - 1)}</td>
                                    <td>{data.title}</td>
                                    <td>{data.Date.substring(0, 10)}</td>
                                    <td>{data.timings}</td>
                                    <td>{data.description || "-"} </td>
                                    <td>{data.whyThisEventt || "-"}</td>
                                    <td>
                                        <div className="options">
                                            <div title='Edit' className='edit' onClick={() => handleEditBtn(data)} >
                                                <img src={edit} alt="trash" />
                                            </div>

                                            <div title='Delete' className='delete' onClick={() => deleteEvent(data._id)}>
                                                <img src={trash} alt="trash" />
                                            </div>

                                            <div title='View' className='view' >
                                                <img src={view} alt="view" onClick={() => handleView(data)} />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}

                    </table>
                </div>
                }

                {isViewActive ? veiwEventPopup() : null}
            </div>
        </div>
    );

};

export default RightViewEvent;