import React from 'react'
import { Navigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode';

const CheckLoginRoute = ({ children }) => {

    const token = localStorage.getItem('token')

    if (token !== null) {
        const decoded = jwt_decode(token);
        const expirationTime = decoded.exp * 1000;

        if (Date.now() <= expirationTime) { return <Navigate to="/dashboard" />; } else {
            localStorage.clear()
            return <Navigate to="/" />;
        }
    }
    // decode the jwt token and check the expiration time

    // if token is not there redirect to login or token expired
    return children
}

export default CheckLoginRoute