import React, { useState, useEffect } from "react";
import "./RightParameters.css";
import axios from "axios";
import { toast } from "react-toastify";
import save from "../../assets/icons/save.svg";

const RightParameters = () => {
  const [loading, setLoading] = useState(false);
  const [healthScore, setHealthScore] = useState([]);
  const [healthScoreLoading, setHealthScoreLoading] = useState(false);
  const [isEditActive, setIsEditActive] = useState(false);
  const [scoreId, setScoreId] = useState("");

  const [healthScoreDetails, setHealthScoreDetails] = useState({
    healthScore: "",
  });

  const token = localStorage.getItem("token");

  useEffect(() => {
    getHealthScore();
    // eslint-disable-next-line
  }, []);

  const getHealthScore = async () => {
    try {
      setHealthScoreLoading(true);
      const response = await axios(
        "https://api.theeliteqa.com/api/health/get/health/score",
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.status === 200) {
        setHealthScore(response.data.data);
        setLoading(false);
        setHealthScoreLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createHealthScore = async () => {
    try {
      setLoading(true);
      const body = {
        healthScoreDetails,
      };
      const updateUrl = `https://api.theeliteqa.com/api/health/update/score/${scoreId}`;
      // const createUrl = 'https://k82gsppxd4.execute-api.ap-south-1.amazonaws.com/api/health/add/health/score'
      await axios.post(updateUrl, body.healthScoreDetails, {
        headers: {
          token: token,
        },
      });

      getHealthScore();
      toast.success("Health score updated successfully.");
      setHealthScore({
        healthScore: "",
      });
      setIsEditActive(false);
      setScoreId("");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to update health score.");
      console.log(error);
    }
  };

  // const deleteHealthScore = async (id) => {
  //     try {
  //         await axios.delete(`https://k82gsppxd4.execute-api.ap-south-1.amazonaws.com/api/health/remove/score/${id}`, {
  //             headers: {
  //                 token: token
  //             }
  //         });
  //         toast.success('Health Score deleted successfully.');
  //         getHealthScore()
  //     } catch (error) {
  //         toast.error('Failed to delete department.');
  //     }
  // }

  const handleChange = (name) => (event) => {
    const value = event.target.value;
    setHealthScoreDetails({ ...healthScore, [name]: value });
  };

  const scoreArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleEdit = (data) => {
    setIsEditActive(true);
    setScoreId(data._id);
    setHealthScoreDetails({
      healthScore: data.healthScore,
    });
  };

  return (
    <div className="add-health-score-container">
      <div className="add-topic-form-container">
        <div className="heading">
          <div className="title">
            <div className="sidebar"></div>
            <span>Add Health Score</span>
          </div>

          <div className="form">
            {/* <input type="number" placeholder='Enter health score' name="healthScore"
                            onChange={handleChange("healthScore")}
                            value={healthScoreDetails.healthScore}
                        /> */}
            {isEditActive ? (
              <select
                name="healthScore"
                onChange={handleChange("healthScore")}
                value={healthScoreDetails.healthScore}
              >
                {scoreArray.map((data) => {
                  return <option value={data}>{data}</option>;
                })}
              </select>
            ) : null}
            {isEditActive ? (
              <button
                title="Update"
                onClick={() => {
                  createHealthScore();
                }}
              >
                <img src={save} alt="save" />
                {loading ? (
                  <i class="fa fa-circle-o-notch fa-spin"></i>
                ) : (
                  "Update"
                )}
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div className="list-topic">
        <div className="heading">
          <div className="title-group">
            <div className="sidebar"></div>
            <span>Added Health Scores</span>
          </div>
        </div>
        {healthScoreLoading ? (
          <div className="loading-container">
            <i
              class="fa fa-circle-o-notch fa-spin fa-5x"
              style={{
                color: "#FF6813",
              }}
            ></i>
          </div>
        ) : (
          <div className="topic-container">
            <table>
              <tr>
                <th>Sr.No</th>
                <th>Health Score</th>
                <th>Options</th>
              </tr>

              {healthScore.map((data, index) => {
                return (
                  <tr>
                    <td>1</td>
                    <td>{data.healthScore}</td>
                    <td>
                      <div className="options">
                        {/* Edit Icon */}
                        <div
                          className="editIconCircle"
                          title="Edit"
                          onClick={() => {
                            handleEdit(data);
                          }}
                        >
                          <svg
                            className="editIcon"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_1351_1043)">
                              <path
                                d="M9.16666 3.33334H3.33332C2.8913 3.33334 2.46737 3.50893 2.15481 3.82149C1.84225 4.13405 1.66666 4.55798 1.66666 5V16.6667C1.66666 17.1087 1.84225 17.5326 2.15481 17.8452C2.46737 18.1577 2.8913 18.3333 3.33332 18.3333H15C15.442 18.3333 15.8659 18.1577 16.1785 17.8452C16.4911 17.5326 16.6667 17.1087 16.6667 16.6667V10.8333"
                                stroke="blue"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M15.4167 2.08333C15.7482 1.75181 16.1978 1.56557 16.6667 1.56557C17.1355 1.56557 17.5851 1.75181 17.9167 2.08333C18.2482 2.41485 18.4344 2.86449 18.4344 3.33333C18.4344 3.80217 18.2482 4.25181 17.9167 4.58333L9.99999 12.5L6.66666 13.3333L7.49999 10L15.4167 2.08333Z"
                                stroke="blue"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1351_1043">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>

                        {/* Delete Icon */}
                        {/* <div className='deleteIconCircle' title='Delete' onClick={() => {
                                                deleteHealthScore(data._id)
                                            }}>
                                                <svg className='deleteIcon' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.5 5H4.16667H17.5" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M15.8333 5V16.6667C15.8333 17.1087 15.6577 17.5326 15.3451 17.8452C15.0326 18.1577 14.6087 18.3333 14.1666 18.3333H5.83329C5.39127 18.3333 4.96734 18.1577 4.65478 17.8452C4.34222 17.5326 4.16663 17.1087 4.16663 16.6667V5M6.66663 5V3.33333C6.66663 2.89131 6.84222 2.46738 7.15478 2.15482C7.46734 1.84226 7.89127 1.66667 8.33329 1.66667H11.6666C12.1087 1.66667 12.5326 1.84226 12.8451 2.15482C13.1577 2.46738 13.3333 2.89131 13.3333 3.33333V5" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M8.33337 9.16667V14.1667" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M11.6666 9.16667V14.1667" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div> */}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default RightParameters;
