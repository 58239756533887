import React from 'react'
import './ViewRolePopup.css'
import close from "../../assets/icons/close.svg";

const ViewRolePopup = ({ closeView, viewData, role }) => {
    const filteredSkills = viewData.skills.filter((data) => data.skillId !== null);
    return (
        <div className='view-role-popup'>
            <div className='view-container'>
                <div className="heading">
                    <div className='left-side'>
                        <div className="sidebar"></div>
                        <span>View Role</span>
                    </div>
                    <img src={close} alt="close" onClick={closeView} />
                </div>

                <div className='form-container'>
                    <div>
                        <label>Role</label>
                        <div className='role-container'>{role}</div>
                    </div>

                    <div style={{ marginTop: 20 }}>
                        <label>Skills</label>
                        {
                            filteredSkills.length !== 0 ? <div className="tag-container">
                                {
                                    filteredSkills.map((data) => {
                                        return <div className='tag'>
                                            {data.skillId.skills}
                                        </div>
                                    })
                                }
                            </div> : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewRolePopup