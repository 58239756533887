import React from 'react';
import close from "../../assets/icons/close.svg";
import './EditValidity.css';
import save from "../../assets/icons/save.svg";
import { validateInvalidChar } from '../../functions/validateInvalidChar';


const EditValidityPopup = ({ validity, handleChange, cancelEdit, loading, updateValidity, clients, packages }) => {

    const formatDate = (date) => {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    };

    return (
        <div className='edit-validity-popup'>
            <div className='skill-edit'>
                <div className="heading">
                    <div className="left-side">
                        <div className="sidebar"></div>
                        <span>Edit Validity</span>
                    </div>

                    <div className='button-container'>

                        <button onClick={updateValidity}>
                            <img src={save} alt="save" />
                            {loading ? <i class="fa fa-circle-o-notch fa-spin"></i> : "Update"}
                        </button>
                        <img src={close} alt="close" onClick={cancelEdit} />
                    </div>
                </div>

                <div className='form-container'>
                    <div className='input'>
                        <label>Company Name *</label>
                        <select value={validity.clientId} onChange={handleChange("clientId")}>
                            <option value="">Select Client</option>
                            {
                                clients.map(data => {
                                    return <option key={data._id} value={data._id}>{data.companyName}</option>;
                                })
                            }
                        </select>
                    </div>

                    <div className='input'>
                        <label>Package *</label>
                        <select value={validity.packageId} onChange={handleChange("packageId")}>
                            <option value="">Select Package</option>
                            {
                                packages.map(data => {
                                    return <option key={data._id} value={data._id}>{data.name}</option>;
                                })
                            }
                        </select>
                    </div>
                </div>

                <div className="form-container">
                    <div className='input'>
                        <label>Start Date</label>
                        <input type="date" placeholder='Start Date' onChange={handleChange("startDate")} name="startDate" value={formatDate(validity.startDate)} />
                    </div>
                    <div className="input">
                        <label>No. of Days</label>
                        <input placeholder='No. of Days' onChange={handleChange("numberOfDays")} name="numberOfDays" value={validity.numberOfDays} type="number" onKeyDown={validateInvalidChar} />
                    </div>
                </div>

                <div className="form-container">
                    <div className='input'>
                        <label>No. of Invites</label>
                        <input placeholder='No. of Days' onChange={handleChange("numberOfInvites")} name="numberOfInvites" value={validity.numberOfInvites} type="number" onKeyDown={validateInvalidChar} />
                    </div>
                    <div className="input">
                        <label>No. of Dates</label>
                        <input placeholder='No. of Dates' onChange={handleChange("numberOfDates")} name="numberOfDates" value={validity.numberOfDates} type="number" onKeyDown={validateInvalidChar} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditValidityPopup;