import React, { useState, useEffect, useContext } from "react";
import "./RightAddEvent.css";
import axios from "axios";
import { toast } from "react-toastify";
import DataContext from "../../store/DataProvider";
import addmedia from "../../assets/icons/addmedia.svg";
import close from "../../assets/icons/contactclose.svg";
import save from "../../assets/icons/save.svg";
import cancel from "../../assets/icons/cancel.svg";
import { validateSpecialChar } from "../../functions/validateSpecialChar";
import { validateTextInput } from "../../functions/validateTextInput";
import moment from "moment/moment";

const RightAddEvent = () => {
  const context = useContext(DataContext);
  const [loading, setloading] = useState(false);
  const [loadingImage, setloadingImage] = useState(false);
  const [loadingSpeakerImage, setloadingSpeakerImage] = useState(false);
  const [formIndex, setFormIndex] = useState(0);
  const [counter, setCounter] = useState(1);
  const [array, setarray] = useState([]);
  const [endTiming, setEndTiming] = useState("");

  const [speakerFields, setspeakerFields] = useState([
    //details of any new speaker
    {
      name: "",
      designation: "",
      image: "",
      linkedIn: "",
    },
  ]);
  const [eventDetails, seteventDetails] = useState({
    //details of any new event
    title: "",
    Date: "",
    timings: "",
    description: "",
    whyThisEvent: "",
    image: "",
    speaker: [],
  });

  const token = localStorage.getItem("token");

  const createEvent = async () => {
    // route to create new event
    try {
      setloading(true);
      const time = dateDifference(endTiming);
      const body = {
        title: eventDetails.title,
        Date: eventDetails.Date,
        timings: time,
        description: eventDetails.description,
        whyThisEvent: eventDetails.whyThisEvent,
        image: eventDetails.image,
        speaker: eventDetails.speaker,
      };

      await axios.post(
        "https://api.theeliteqa.com/api/event/createEvent",
        body,
        {
          headers: {
            token: token,
          },
        }
      );
      toast.success("Event created successfully");
      seteventDetails({
        title: "",
        Date: "",
        timings: "",
        description: "",
        whyThisEvent: "",
        image: "",
        speaker: [],
      });
      setspeakerFields({
        name: "",
        designation: "",
        image: "",
        linkedIn: "",
      });
      setloading(false);
    } catch (error) {
      setloading(false);
      // console.log(error)
      toast.error("Failed to create event.");
    }
  };

  const updateEvent = async () => {
    //Axios req to update a function
    try {
      setloading(true);
      const body = {
        eventDetails,
      };

      await axios.put(
        `https://api.theeliteqa.com/api/event/updateEventById/${context.eventId}`,
        body.eventDetails,
        {
          headers: {
            token: token,
          },
        }
      );

      toast.success("Event Updated Succesfully");
      context.changeEditActive("");
      seteventDetails({
        title: "",
        Date: "",
        timings: "",
        description: "",
        whyThisEvent: "",
        image: "",
        speaker: [],
      });
      setspeakerFields({
        name: "",
        designation: "",
        image: "",
        linkedIn: "",
      });
      setloading(false);
    } catch (error) {
      // console.log(error)
      setloading(false);
      toast.error("Failed to update event");
    }
  };

  const handleChange = (name) => (event) => {
    const value = event.target.value;
    seteventDetails({ ...eventDetails, [name]: value });
  };

  const validateData = () => {
    if (eventDetails.title === "") {
      return toast.error("Title is empty");
    } else if (eventDetails.timings === "") {
      return toast.error("Enter timings of the event");
    } else if (new Date(eventDetails.Date) < new Date()) {
      return toast.error("Please select a valid date.");
    } else {
      context.editActive === "event-edit" ? updateEvent() : createEvent();
    }
  };

  // const checkButtonStatus = () => {                             //To check update, loading, save button
  //     if (loading) return "Loading . . .";
  //     if (context.editActive === 'event-edit') return "Update";
  //     return "Save";
  // };

  const cancelEdit = () => {
    context.changeEditActive("");
    context.addEventId("");
    context.changeActive("viewEvent");
    seteventDetails({
      title: "",
      Date: "",
      timings: "",
      description: "",
      whyThisEvent: "",
      image: "",
      speaker: [],
    });
    setspeakerFields({
      name: "",
      designation: "",
      image: "",
      linkedIn: "",
    });
    setCounter(1);
  };

  const formatDate = (date) => {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const handleClick = () => {
    //function to add speakers
    const isWebsiteValid = checkWebsite(speakerFields[formIndex]?.linkedIn);
    if (speakerFields[formIndex]?.name === "") {
      return toast.error("Enter name of the speaker");
    }
    if (speakerFields[formIndex]?.designation === "") {
      return toast.error("Enter designation of the speaker");
    }
    if (speakerFields[formIndex]?.image === "") {
      return toast.error("Enter image of the speaker");
    }
    if (isWebsiteValid === "Invalid LinkedIn URL") {
      return toast.error(isWebsiteValid);
    } else {
      setCounter(counter + 1);
      // console.log("speaker fields", speakerFields)
      seteventDetails({ ...eventDetails, speaker: speakerFields });
      setspeakerFields([
        ...speakerFields,
        {
          name: "",
          designation: "",
          image: "",
          linkedIn: "",
        },
      ]);
      setarray([...array, speakerFields[formIndex]]);
      setFormIndex(speakerFields.length);
    }
  };

  const handleSpeakerForm = (event, name, index) => {
    //onchange function of speaker form

    setspeakerFields(
      (current) =>
        current.length &&
        current.map((obj, i) => {
          if (index === i) {
            return { ...obj, [name]: event.target.value };
          }

          return obj;
        })
    );
    seteventDetails({ ...eventDetails, speaker: speakerFields });
  };

  const removeSpeaker = async (index) => {
    if (speakerFields.length > 1) {
      setFormIndex(0);
      // filterSpeaker = speakerFields.filter((data, i) => { return i !== index });
      setarray(
        array.filter((data, i) => {
          return i !== index;
        })
      );
      // setarray(array);
      seteventDetails({ ...eventDetails, speaker: array });
    } else {
      toast.error("Atleast add one speaker");
    }
  };

  const addImage = () => {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = async (e) => {
      setloadingImage(true);
      const url = await uploadLogoToS3(e.target.files[0]);
      seteventDetails({ ...eventDetails, image: url });

      setloadingImage(false);
    };

    input.click();
  };

  const addPhoto = (index) => {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";

    input.onchange = async (e) => {
      setloadingSpeakerImage(true);
      const url = await uploadLogoToS3(e.target.files[0]);
      setspeakerFields(
        (current) =>
          current.length &&
          current.map((obj, i) => {
            if (index === i) {
              // return { ...obj, image: url };
              obj.image = url;
            }

            return obj;
          })
      );
      setloadingSpeakerImage(false);
      seteventDetails({ ...eventDetails, speaker: speakerFields });
    };

    input.click();
  };

  const uploadLogoToS3 = async (file) => {
    const uploadURL = await axios.get(`https://api.theeliteqa.com/api/S3Url`, {
      headers: {
        token: token,
      },
    });
    await axios.request({
      method: "PUT",
      headers: {
        "Content-Type": file.type,
      },
      url: uploadURL.data.uploadURL,
      data: file,
    });
    const imageURL = uploadURL.data.uploadURL.split("?")[0];
    return imageURL;
  };

  useEffect(() => {
    if (context.editActive === "event-edit") {
      seteventDetails({
        title: context.eventData?.title,
        Date: context.eventData?.Date,
        timings: context.eventData?.timings,
        description: context.eventData?.description,
        whyThisEvent: context.eventData?.whyThisEvent,
        image: context.eventData?.image,
        speaker: context.eventData?.speaker,
      });
      setspeakerFields(context.eventData?.speaker);
      setarray(context.eventData?.speaker);
    }
  }, [context.changeEditActive === "event-edit"]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkWebsite = (url) => {
    let regSite =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm; //eslint-disable-line
    let linkedinUrl = "https://www.linkedin.com/";
    let initialUrl = url?.substring(0, 25);
    if (!regSite.test(url)) {
      return "Invalid LinkedIn URL";
    } else if (linkedinUrl !== initialUrl) {
      return "Invalid LinkedIn URL";
    }
  };

  const dateDifference = () => {
    let diff = moment.duration(
      moment(endTiming, "HH:mm:ss a").diff(
        moment(eventDetails.timings, "HH:mm:ss a")
      )
    );
    return `${diff.hours()} Hour ${diff.minutes()} minutes`;
  };

  const handleEndDate = (e) => {
    setEndTiming(e.target.value);
  };

  return (
    <div className="add-event-container">
      <div className="add-event-form">
        <div className="heading">
          <div className="left-side">
            <div className="sidebar"></div>
            <span>Add Event</span>
          </div>
          <div className="button-container">
            {context.editActive === "event-edit" ? (
              <button className="cancel" onClick={cancelEdit} title="Cancel">
                <img src={cancel} alt="save" />
                Cancel
              </button>
            ) : null}
            <button
              className="save"
              style={
                context.editActive === "event-edit" ? { width: "112px" } : null
              }
              onClick={validateData}
              title={context.editActive === "event-edit" ? "Update" : "Save"}
            >
              <img src={save} alt="save" />
              {loading ? (
                <i class="fa fa-circle-o-notch fa-spin"></i>
              ) : context.editActive === "event-edit" ? (
                "Update"
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>

        <div className="form-container1">
          <div className="logo-container">
            <div className="add-eventImage" onClick={addImage}>
              <img src={addmedia} alt="addmedia" />
              <span>Add Event Image</span>
            </div>
            {loadingImage ? (
              <div className="loading-container">
                <i
                  className="fa fa-circle-o-notch fa-spin fa-3x"
                  style={{ color: "#FF6813" }}
                ></i>
              </div>
            ) : (
              <div>
                {eventDetails?.image !== "" &&
                eventDetails?.image !== "No Image" ? (
                  <div className="logo">
                    <img src={eventDetails?.image} alt="" />
                    <img
                      src={close}
                      alt=""
                      className="removeLogo"
                      onClick={() => {
                        seteventDetails({ ...eventDetails, image: "No Image" });
                      }}
                    />
                  </div>
                ) : null}
              </div>
            )}
          </div>

          <div className="row">
            <div className="input-container">
              <label>Title *</label>
              <input
                value={eventDetails?.title}
                type="text"
                placeholder="Title Here"
                onChange={handleChange("title")}
                onKeyDown={validateSpecialChar}
              />
            </div>
          </div>
          <div className="row">
            <div className="input-container">
              <label>Date</label>
              <input
                type="date"
                onChange={handleChange("Date")}
                value={
                  context.editActive === "event-edit"
                    ? formatDate(eventDetails?.Date)
                    : eventDetails.Date
                }
              />
            </div>

            <div className="input-container">
              <label> Start Timings *</label>
              <input
                value={eventDetails?.timings}
                type="time"
                placeholder="Select Timings"
                onChange={handleChange("timings")}
              />
            </div>

            <div className="input-container">
              <label>End Timings *</label>
              <input
                value={endTiming}
                type="time"
                placeholder="Select Timings"
                onChange={handleEndDate}
              />
            </div>
          </div>

          <div className="row">
            <div className="input-container">
              <label>Why This Event</label>
              <textarea
                type="text"
                placeholder="Why This Event Here"
                onChange={handleChange("whyThisEvent")}
                value={eventDetails?.whyThisEvent}
              ></textarea>
            </div>

            <div
              className="input-container"
              id=" description"
              style={{ margin: "0px 0px 0px 24px" }}
            >
              <label>Description</label>
              <textarea
                placeholder="Description Here"
                onChange={handleChange("description")}
                value={eventDetails?.description}
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      {/* speaker form  */}

      <div div className="add-speaker-form">
        <div className="heading">
          <div className="left-side">
            <div className="sidebar"></div>
            <span>Add Speaker</span>
          </div>
          <div className="button-container">
            <button onClick={handleClick} title="Add Speaker">
              <img src={save} alt="save" />
              Add
            </button>
          </div>
        </div>

        <div className="form-container2">
          <div className="logo-container">
            <div className="add-eventImage">
              <img
                src={addmedia}
                alt="addmedia"
                onClick={() => addPhoto(formIndex)}
              />
              <span>Add Speaker Image *</span>
            </div>
            {loadingSpeakerImage ? (
              <div className="loading-container">
                <i
                  className="fa fa-circle-o-notch fa-spin fa-3x"
                  style={{ color: "#FF6813" }}
                ></i>
              </div>
            ) : (
              <div>
                {speakerFields[formIndex]?.image !== "" &&
                speakerFields[formIndex]?.image !== "No Logo" ? (
                  <div className="logo">
                    <img src={speakerFields[formIndex]?.image} alt="" />
                  </div>
                ) : null}
              </div>
            )}
          </div>
          <div className="row">
            <div className="input-container">
              <label>Name *</label>
              <input
                value={speakerFields[formIndex]?.name}
                type="text"
                placeholder="Speaker Name"
                onChange={(e) => handleSpeakerForm(e, "name", formIndex)}
                onKeyDown={(e) => {
                  validateTextInput(e);
                  validateSpecialChar(e);
                }}
              />
            </div>

            <div className="input-container">
              <label>Designation *</label>
              <input
                value={speakerFields[formIndex]?.designation}
                type="text"
                placeholder="Speaker's Designation Here"
                onChange={(e) => handleSpeakerForm(e, "designation", formIndex)}
                onKeyDown={(e) => {
                  validateTextInput(e);
                  validateSpecialChar(e);
                }}
              />
            </div>

            <div className="input-container">
              <label>LinkedIn URL *</label>
              <input
                value={speakerFields[formIndex]?.linkedIn}
                type="text"
                placeholder="LinkedIn URL here"
                onChange={(e) => handleSpeakerForm(e, "linkedIn", formIndex)}
              />
              {speakerFields[formIndex]?.linkedIn !== "" ? (
                <span
                  style={{
                    marginTop: "5px",
                    color: "red",
                  }}
                >
                  {checkWebsite(speakerFields[formIndex]?.linkedIn)}
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="speaker-container">
        {array?.map((data, index) => {
          if (array.length) {
            return (
              <div
                className="speaker-card"
                key={index}
                style={formIndex === index ? { border: "1px solid red" } : null}
                onClick={() => setFormIndex(index)}
              >
                <div className="heading">
                  <span>Speaker</span>
                  <img
                    src={close}
                    alt="close"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      removeSpeaker(index);
                    }}
                  />
                </div>
                <span className="name">Name: {data?.name}</span>
                <span>Designation: {data?.designation}</span>
                <span>LinkedIn: {data?.linkedIn}</span>
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

export default RightAddEvent;
