import React, { useContext } from 'react';
import "./Dashboard.css";
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/left-sidebar/Sidebar';
import DataContext from '../../store/DataProvider';
import RightDashboard from '../../components/right-dashboard/right_dashboard';
import RightAddClient from '../../components/right_add_client/add_client';
import RightAddPackage from '../../components/right_add_package/add_package';
import RightAddSkill from '../../components/right_add_skill/add_skill';
import RightOrder from '../../components/right_order/RightOrder';
import RightViewClient from '../../components/right_view_client/RightViewClient';
import RightViewOrders from '../../components/right_view_orders/RightViewOrders';
import RightAddTopic from '../../components/right_add_topics/add_topic';
import RightManageStaff from '../../components/right_manage_staff/RightManageStaff';
import RightAddJobRole from '../../components/right_add_job_role/RightAddJobRole';
import RightAddDepartment from '../../components/right_add_department/RightDepartment';
import RightAddValidity from '../../components/right_add_validity/add_validity';
import RightAddEvent from '../../components/right_add_event/RightAddEvent';
import RightViewEvent from '../../components/right_view_event/RightViewEvent';
import RightManageClientUser from '../../components/right_manage_client_user/RightManageClientUser';
import RightViewManageClientUser from '../../components/right_view_manage_client_user/RightViewManageClientUser';
import RightParameters from '../../components/right_manage_parameters/RightParameters';
import RightManageExperience from '../../components/right_manage_experience/RightManageExperience';
import RightAddDifficultyLevel from '../../components/right_add_difficulty_level/RightAddDifficultyLevel';


const Dashboard = () => {
    const context = useContext(DataContext);
    return (
        <div>
            <Navbar />
            <div className='dashboard-container'>
                <Sidebar />
                <div className="rightside-container">
                    {context.active === 'home' ? <RightDashboard /> : null}
                    {context.active === 'onboardclient' || context.active === 'manageclient' ? <RightAddClient /> : null}
                    {context.active === 'managepackage' ? <RightAddPackage /> : null}
                    {context.active === 'skills' ? <RightAddSkill /> : null}
                    {context.active === "manageorders" || context.active === 'generateorders' ? <RightOrder /> : null}
                    {context.active === "viewclient" ? <RightViewClient /> : null}
                    {context.active === "vieworders" ? <RightViewOrders /> : null}
                    {context.active === "managestaff" ? <RightManageStaff /> : null}
                    {context.active === "roles" || context.active === "librarysettings" ? <RightAddJobRole /> : null}
                    {context.active === "topics" ? <RightAddTopic /> : null}
                    {context.active === "managedepartment" ? <RightAddDepartment /> : null}
                    {context.active === "managevalidity" ? <RightAddValidity /> : null}
                    {context.active === "manageevents" || context.active === "onboardevents" ? <RightAddEvent /> : null}
                    {context.active === "viewEvent" ? <RightViewEvent /> : null}
                    {context.active === "manageclientuser" || context.active === "clientuser" ? <RightManageClientUser /> : null}
                    {context.active === "viewclientuser" ? <RightViewManageClientUser /> : null}
                    {context.active === "manageparameters" ? <RightParameters /> : null}
                    {context.active === "manageexperience" ? <RightManageExperience /> : null}
                    {context.active === "managelevel" ? <RightAddDifficultyLevel /> : null}
                </div>
            </div>
        </div>

    );
};

export default Dashboard;
