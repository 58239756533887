import React, { useState, useEffect, useContext, useMemo } from "react";
import "./add_client.css";
import axios from "axios";
import { toast } from "react-toastify";
import DataContext from "../../store/DataProvider";
import addmedia from "../../assets/icons/addmedia.svg";
import Avatar from "../../assets/icons/Avatar.svg";
import close from "../../assets/icons/contactclose.svg";
import FemaleAvatar from "../../assets/icons/FemaleAvatar.svg";
import save from "../../assets/icons/save.svg";
import cancel from "../../assets/icons/cancel.svg";
import { validateInvalidChar } from "../../functions/validateInvalidChar";
import { validateTextInput } from "../../functions/validateTextInput";
import { validateEmailSpecialChar } from "../../functions/validateEmailSpecialChar";
import { validateTanGst } from "../../functions/validateTanGst";
import DataListInput from "react-datalist-input";

const AddClient = () => {
  const context = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [counter, setCounter] = useState(1);
  const [formIndex, setFormIndex] = useState(0);
  const [contactPersonFeilds, setContactPersonFeilds] = useState([
    {
      role: "",
      name: "",
      contactno: "",
      email: "",
      gender: "",
    },
  ]);

  const [clientDetails, setClientDetails] = useState({
    packageId: "",
    startDate: "",
    companyName: "",
    companyEmail: "",
    contact: "",
    website: "",
    logo: "",
    gstn: "",
    tanNo: "",
    companyAddress: "",
    contactPerson: [],
    coolingPeriod: "",
  });
  const [array, setarray] = useState([]);

  const token = localStorage.getItem("token");

  useEffect(() => {
    getPackages();
    getDepartments();
    if (context.editActive === "client-edit") {
      setClientDetails({
        packageId: context.clientData.packageId?._id,
        startDate: context.clientData.startDate,
        companyName: context.clientData.companyName,
        companyEmail: context.clientData.companyEmail,
        contact: context.clientData.contact,
        website: context.clientData.website,
        logo: context.clientData.logo,
        gstn: context.clientData.gstn,
        tanNo: context.clientData.tanNo,
        companyAddress: context.clientData.companyAddress,
        contactPerson: context.clientData.contactPerson,
        coolingPeriod: context.clientData.coolingPeriod,
      });
      setContactPersonFeilds(context.clientData.contactPerson);
      setCounter(context.clientData.contactPerson.length);
      setarray(context.clientData.contactPerson);
    }
  }, [context.changeEditActive === "client-edit"]); // eslint-disable-line react-hooks/exhaustive-deps

  const isEmail = (val) => {
    let regEmail = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}"); //eslint-disable-line
    if (!regEmail.test(val)) {
      return "Invalid Email";
    }
  };

  const checkPhoneNumber = (no) => {
    let regPhone =
      /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/; //eslint-disable-line
    if (!regPhone.test(no)) {
      return "Invalid Phone Number";
    }
  };

  const checkWebsite = (site) => {
    let regSite =
      /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    if (!regSite.test(site)) {
      return "Invalid Website";
    }
  };

  const checkGstn = (gstn) => {
    let regGstn = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
    if (!regGstn.test(gstn)) {
      return "Invalid GSTN";
    }
  };

  const getPackages = async () => {
    try {
      const response = await axios("https://api.theeliteqa.com/api/package", {
        headers: {
          token: token,
        },
      });
      setPackages(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDepartments = async () => {
    try {
      const response = await axios(
        "https://api.theeliteqa.com/api/department",
        {
          headers: {
            token: token,
          },
        }
      );
      setDepartments(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (name) => (event) => {
    const value =
      name === "gstn" || name === "tanNo"
        ? event.target.value.toUpperCase()
        : event.target.value;
    setClientDetails({ ...clientDetails, [name]: value });
  };

  // const handleChangeSelect = (name) => (event) => {
  //     const value = event.target.value;
  //     setClientDetails({ ...clientDetails, [name]: value });
  // };

  const addLogo = () => {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = async (e) => {
      const logo = await uploadLogoToS3(e.target.files[0]);
      setClientDetails({ ...clientDetails, logo: logo });
    };
    input.click();
  };

  const formatDate = (date) => {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const handleContactForm = (event, name, index) => {
    const value =
      event === "Male" || event === "Female" ? event : event.target.value;

    setContactPersonFeilds((current) =>
      current.map((obj, i) => {
        if (index === i) {
          return { ...obj, [name]: value };
        }

        return obj;
      })
    );
    setClientDetails({ ...clientDetails, contactPerson: contactPersonFeilds });
  };

  // const removeContactPerson = (index) => {
  //     if (contactPersonFeilds.length > 1) {
  //         setFormIndex(0);
  //         const filterContactPersons = contactPersonFeilds.filter((data, i) => i !== index);
  //         setContactPersonFeilds(filterContactPersons);
  //     } else {
  //         toast.error("Atleast add one contact person.");
  //     }
  // };

  const removeContactPerson = async (index) => {
    if (contactPersonFeilds.length > 1) {
      setFormIndex(0);

      setarray(
        array.filter((data, i) => {
          return i !== index;
        })
      );
      // setarray(array);
      setClientDetails({ ...clientDetails, contactPerson: array });
    } else {
      toast.error("Atleast add one speaker");
    }
  };
  // const handleClick = () => {
  //     setCounter(counter + 1);
  //     setContactPersonFeilds([...contactPersonFeilds, {
  //         role: "",
  //         name: "",
  //         contactno: "",
  //         email: "",
  //         gender: ""
  //     }]);
  //     setFormIndex(contactPersonFeilds.length);
  // };

  const handleClick = () => {
    if (contactPersonFeilds[formIndex]?.name === "") {
      return toast.error("Enter name of the speaker");
    } else if (contactPersonFeilds[formIndex]?.email === "") {
      return toast.error("Enter email of contact person.");
    } else if (contactPersonFeilds[formIndex]?.contactno === "") {
      return toast.error("Enter contact number of the person");
    } else if (contactPersonFeilds[formIndex]?.role === "") {
      return toast.error("Select department");
    } else {
      setCounter(counter + 1);
      setClientDetails({
        ...clientDetails,
        contactPerson: contactPersonFeilds,
      });
      setContactPersonFeilds([
        ...contactPersonFeilds,
        {
          role: "",
          name: "",
          contactno: "",
          email: "",
          gender: "",
        },
      ]);
      setarray([...array, contactPersonFeilds[formIndex]]);
      setFormIndex(contactPersonFeilds.length);
    }
  };

  const cancelEdit = () => {
    context.changeEditActive("");
    context.addClientId("");
    context.changeActive("viewclient");
    setClientDetails({
      packageId: "",
      startDate: "",
      companyName: "",
      companyEmail: "",
      contact: "",
      website: "",
      logo: "",
      gstn: "",
      tanNo: "",
      companyAddress: "",
      contactPersonFeilds: [],
      coolingPeriod: "",
    });
    setContactPersonFeilds([
      {
        role: "",
        name: "",
        contactno: "",
        email: "",
        gender: "",
      },
    ]);
    setCounter(1);
  };

  const updateClient = async () => {
    try {
      setLoading(true);
      const body = {
        clientDetails,
      };
      await axios.put(
        `https://api.theeliteqa.com/api/client/update/${context.clientId}`,
        body.clientDetails,
        {
          headers: {
            token: token,
          },
        }
      );
      toast.success("Client updated successfully.");
      context.changeEditActive("");
      setClientDetails({
        packageId: "",
        startDate: "",
        companyName: "",
        companyEmail: "",
        contact: "",
        website: "",
        logo: "",
        gstn: "",
        tanNo: "",
        companyAddress: "",
        coolingPeriod: "",
        contactPerson: [],
      });
      setContactPersonFeilds([
        {
          role: "",
          name: "",
          contactno: "",
          email: "",
          gender: "",
        },
      ]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to update client.");
    }
  };

  const validateData = () => {
    const isValidEmail = isEmail(clientDetails.companyEmail);
    const isPhoneValid = checkPhoneNumber(clientDetails.contact);
    const isWebsiteValid = checkWebsite(clientDetails.website);
    const isGstnValid = checkGstn(clientDetails.gstn);
    if (
      isValidEmail === "Invalid Email" ||
      isPhoneValid === "Invalid Phone Number" ||
      clientDetails.companyName === "" ||
      clientDetails.startDate === "" ||
      isWebsiteValid === "Invalid Website"
    ) {
      return toast.error(
        isValidEmail || isPhoneValid || isWebsiteValid || isGstnValid
      );
    } else {
      context.editActive === "client-edit" ? updateClient() : createClient();
    }
  };

  const uploadLogoToS3 = async (file) => {
    const uploadURL = await axios.get(`https://api.theeliteqa.com/api/S3Url`, {
      headers: {
        token: token,
      },
    });
    await axios.request({
      method: "PUT",
      headers: {
        "Content-Type": file.type,
      },
      url: uploadURL.data.uploadURL,
      data: file,
    });
    const imageURL = uploadURL.data.uploadURL.split("?")[0];
    return imageURL;
  };

  const createClient = async () => {
    try {
      console.log(clientDetails);
      setLoading(true);
      const body = {
        clientDetails,
      };
      await axios.post(
        "https://api.theeliteqa.com/api/client/create",
        body.clientDetails,
        {
          headers: {
            token: token,
          },
        }
      );
      toast.success("Client created successfully.");
      setClientDetails({
        packageId: "",
        startDate: "",
        companyName: "",
        companyEmail: "",
        contact: "",
        website: "",
        logo: "",
        gstn: "",
        tanNo: "",
        companyAddress: "",
        contactPerson: [],
      });
      setContactPersonFeilds([
        {
          role: "",
          name: "",
          contactno: "",
          email: "",
          gender: "",
        },
      ]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Failed to create client.");
    }
  };

  const onSelectPackage = (selectedItem) => {
    let id = selectedItem._id;
    setClientDetails({ ...clientDetails, packageId: id });
  };

  const packageItem = useMemo(
    () =>
      packages.map((option) => ({
        key: option.id,
        label: option.name,
        ...option,
      })),
    [packages] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const periodArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  return (
    <div className="add-client-container">
      <div className="add-client-form">
        <div className="heading">
          <div className="left-side">
            <div className="sidebar"></div>
            <span>Add Client Form</span>
          </div>
          <div className="button-container">
            {context.editActive === "client-edit" ? (
              <button className="cancel" onClick={cancelEdit}>
                <img src={cancel} alt="save" />
                Cancel
              </button>
            ) : null}
            <button
              className="save"
              style={
                context.editActive === "client-edit" ? { width: "112px" } : null
              }
              onClick={validateData}
              title={context.editActive === "client-edit" ? "Update" : "Save"}
            >
              <img src={save} alt="save" />
              {loading ? (
                <i class="fa fa-circle-o-notch fa-spin"></i>
              ) : context.editActive === "client-edit" ? (
                "Update"
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>

        <div className="form-container1">
          <div className="logo-container">
            <div className="add-logo" onClick={addLogo}>
              <img src={addmedia} alt="addmedia" />
              <span>Add logo</span>
            </div>
            {clientDetails.logo !== "" && clientDetails.logo !== "No Logo" ? (
              <div className="logo">
                <img src={clientDetails.logo} alt="" />
                <img
                  src={close}
                  alt=""
                  className="removeLogo"
                  onClick={() => {
                    setClientDetails({ ...clientDetails, logo: "No Logo" });
                  }}
                />
              </div>
            ) : null}
          </div>

          <div className="row">
            <div className="input-container">
              <label>Package *</label>
              <DataListInput
                placeholder="Package"
                items={packageItem}
                onSelect={onSelectPackage}
                dropdownClassName="dropdown"
                itemClassName="dropdownItem"
                activeItemClassName="activeItem"
              />
              {/* <select name="packageId" value={clientDetails.packageId} onChange={handleChangeSelect("packageId")} >
                                <option value="">Select Package</option>
                                {
                                    packages.map((data, index) => {
                                        return <option value={data._id} key={index} selected={clientDetails.packageId === data._id ? "selected" : null} >{data.name}</option>;
                                    })
                                }
                            </select> */}
            </div>

            <div className="input-container">
              <label>Date *</label>
              <input
                type="date"
                onChange={handleChange("startDate")}
                value={
                  context.editActive === "client-edit"
                    ? formatDate(clientDetails.startDate)
                    : clientDetails.startDate
                }
              />
            </div>

            <div className="input-container">
              <label>Company *</label>
              <input
                value={clientDetails.companyName}
                type="text"
                placeholder="Company name here"
                onChange={handleChange("companyName")}
                onKeyDown={validateTextInput}
              />
            </div>
          </div>

          <div className="row">
            <div className="input-container">
              <label>Email *</label>
              <input
                value={clientDetails.companyEmail}
                style={
                  clientDetails.companyEmail !== "" &&
                  isEmail(clientDetails.companyEmail) === "Invalid Email"
                    ? { border: "1px solid red" }
                    : null
                }
                type="text"
                placeholder="name@work.com"
                onChange={handleChange("companyEmail")}
                onKeyDown={validateEmailSpecialChar}
              />
              {clientDetails.companyEmail !== "" ? (
                <span
                  style={{
                    marginTop: "5px",
                    color: "red",
                  }}
                >
                  {isEmail(clientDetails.companyEmail)}
                </span>
              ) : (
                <></>
              )}
            </div>

            <div className="input-container">
              <label>Phone No. *</label>
              <input
                value={clientDetails.contact}
                maxLength={12}
                type="number"
                placeholder="1234567890"
                onChange={handleChange("contact")}
                onKeyDown={validateInvalidChar}
              />
              {clientDetails.contact !== "" ? (
                <span
                  style={{
                    marginTop: "5px",
                    color: "red",
                  }}
                >
                  {checkPhoneNumber(clientDetails.contact)}
                </span>
              ) : (
                <></>
              )}
            </div>

            <div className="input-container">
              <label>Website *</label>
              <input
                value={clientDetails.website}
                style={
                  clientDetails.website !== "" &&
                  checkWebsite(clientDetails.website) === "Invalid Website"
                    ? { border: "1px solid red" }
                    : null
                }
                type="text"
                placeholder="https://www.example.com"
                onChange={handleChange("website")}
                onKeyDown={validateEmailSpecialChar}
              />
              {clientDetails.website !== "" ? (
                <span
                  style={{
                    marginTop: "5px",
                    color: "red",
                  }}
                >
                  {checkWebsite(clientDetails.website)}
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="row2">
            <div className="input-container">
              <label>Address *</label>
              <textarea
                placeholder="Particulars"
                onChange={handleChange("companyAddress")}
                value={clientDetails.companyAddress}
              ></textarea>
            </div>

            <div className="column">
              <div className="input-container">
                <label>GSTN</label>
                <input
                  type="text"
                  placeholder="18AABCU9603R1ZM"
                  onChange={handleChange("gstn")}
                  value={clientDetails.gstn}
                  onKeyDown={validateTanGst}
                  maxLength={15}
                />
                {clientDetails.gstn !== "" ? (
                  <span
                    style={{
                      marginTop: "5px",
                      color: "red",
                    }}
                  >
                    {checkGstn(clientDetails.gstn)}
                  </span>
                ) : (
                  <></>
                )}
              </div>

              <div className="input-container">
                <label>Tan No</label>
                <input
                  type="text"
                  placeholder="DMPKR9319R"
                  onChange={handleChange("tanNo")}
                  value={clientDetails.tanNo}
                  maxLength={10}
                  onKeyDown={validateTanGst}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="input-container">
              <label>Cooling Period (In Months)</label>
              <select
                name="coolingPeriod"
                onChange={handleChange("coolingPeriod")}
                value={clientDetails.coolingPeriod}
              >
                {periodArray.map((data) => {
                  return <option value={data}>{data}</option>;
                })}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="add-contact-form">
        <div className="heading">
          <div className="left-side">
            <div className="sidebar"></div>
            <span>Add Contact Person</span>
          </div>
          <div className="button-container">
            <button onClick={handleClick} title="Add">
              <img src={save} alt="save" />
              Add
            </button>
          </div>
        </div>

        <div className="form-container2">
          <div className="row">
            <div className="input-container">
              <label>Name *</label>
              <input
                value={contactPersonFeilds[formIndex]?.name}
                type="text"
                placeholder="Contact person name"
                onChange={(e) => handleContactForm(e, "name", formIndex)}
                onKeyDown={validateTextInput}
              />
            </div>

            {/* <div className='input-container'>
                            <label>Email *</label>
                            <input value={contactPersonFeilds[formIndex]?.email} type="text" placeholder="name@work.com" onChange={(e) => handleContactForm(e, 'email', formIndex)} />
                        </div> */}
            <div className="input-container">
              <label>Email *</label>
              <input
                value={contactPersonFeilds[formIndex]?.email}
                style={
                  contactPersonFeilds[formIndex]?.email !== "" &&
                  isEmail(contactPersonFeilds[formIndex]?.email) ===
                    "Invalid Email"
                    ? { border: "1px solid red" }
                    : null
                }
                type="text"
                placeholder="name@work.com"
                onChange={(e) => handleContactForm(e, "email", formIndex)}
                onKeyDown={validateEmailSpecialChar}
              />
              {contactPersonFeilds[formIndex]?.email !== "" ? (
                <span
                  style={{
                    marginTop: "5px",
                    color: "red",
                  }}
                >
                  {isEmail(contactPersonFeilds[formIndex]?.email)}
                </span>
              ) : (
                <></>
              )}
            </div>

            <div className="input-container">
              <label>Phone No. *</label>
              <input
                value={contactPersonFeilds[formIndex]?.contactno}
                maxLength="12"
                type="number"
                placeholder="1234567890"
                onChange={(e) => handleContactForm(e, "contactno", formIndex)}
                onKeyDown={validateInvalidChar}
              />
              {contactPersonFeilds[formIndex]?.contactno !== "" ? (
                <span
                  style={{
                    marginTop: "5px",
                    color: "red",
                  }}
                >
                  {checkPhoneNumber(contactPersonFeilds[formIndex]?.contactno)}
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="row">
            <div className="input-container">
              <label>Department *</label>
              <select
                name="package"
                value={contactPersonFeilds[formIndex]?.role}
                onChange={(e) => handleContactForm(e, "role", formIndex)}
              >
                <option value="">Select Department</option>
                {departments.map((data, index) => {
                  return (
                    <option
                      value={data.department}
                      key={index}
                      selected={
                        contactPersonFeilds[formIndex]?.role === data.department
                          ? "selected"
                          : null
                      }
                    >
                      {data.department}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="input-container">
              <label>Gender *</label>
              <div className="gender-container">
                <button
                  style={
                    contactPersonFeilds[formIndex]?.gender === "Male"
                      ? { border: "1.2px solid #00C49A", color: "#00C49A" }
                      : null
                  }
                  onClick={() => handleContactForm("Male", "gender", formIndex)}
                >
                  M
                </button>
                <button
                  style={
                    contactPersonFeilds[formIndex]?.gender === "Female"
                      ? { border: "1.2px solid #00C49A", color: "#00C49A" }
                      : null
                  }
                  onClick={() =>
                    handleContactForm("Female", "gender", formIndex)
                  }
                >
                  F
                </button>
              </div>
            </div>
            <div className="input-container" />
          </div>
        </div>
      </div>

      <div className="contact-person-container">
        {array?.map((data, index) => {
          // eslint-disable-line array-callback-return
          if (array.length) {
            return (
              <div
                className="contact-person-card"
                key={index}
                style={formIndex === index ? { border: "1px solid red" } : null}
                onClick={() => setFormIndex(index)}
              >
                <div className="heading">
                  <img
                    src={close}
                    alt="close"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      removeContactPerson(index);
                    }}
                  />
                </div>
                <img
                  src={data?.gender === "Male" ? Avatar : FemaleAvatar}
                  alt="avatar"
                />
                <span className="name">{data?.name}</span>
                <span>{data?.role}</span>
                <span>{data?.contactno}</span>
                <span>{data?.email}</span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default AddClient;
