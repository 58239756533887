import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Login from './pages/login/Login';
import Dashboard from './pages/dashboard/dashboard';
import ProtectedRoutes from "./ProtectedRoutes";
import CheckLoginRoute from './CheckLoginRoute';



const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<CheckLoginRoute><Login /></CheckLoginRoute>} />
        <Route path="/dashboard" element={<ProtectedRoutes><Dashboard /></ProtectedRoutes>} />;
      </Routes>
    </BrowserRouter>
  );
};

export default App;

