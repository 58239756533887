import React, { useState, useEffect, useContext } from "react";
import "./RightViewOrders.css";
import trash from "../../assets/icons/trash.svg";
import axios from "axios";
import { toast } from "react-toastify";
import DataContext from "../../store/DataProvider";
import close from "../../assets/icons/close.svg";
import view from "../../assets/icons/View.svg";
import edit from "../../assets/icons/edit.svg";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyDocument from "../invoice_pdf/Document";

const RightViewOrders = () => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [viewData, setViewData] = useState(null);
  const [isViewActive, setIsViewActive] = useState(false);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const context = useContext(DataContext);
  const token = localStorage.getItem("token");

  useEffect(() => {
    getOrders();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getOrders = async () => {
    try {
      setLoading(true);
      const response = await axios("https://api.theeliteqa.com/api/order/", {
        headers: {
          token: token,
        },
      });
      if (response.data !== undefined) {
        setOrders(response.data.data);
        setTotalPage(Math.floor(response.data.data.length / 10) + 1);
        setLoading(false);
        setPages(orders.length / 10);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteOrder = async (id) => {
    try {
      await axios.delete(`https://api.theeliteqa.com/api/order/remove/${id}`, {
        headers: {
          token: token,
        },
      });
      toast.success("Order deleted successfully.");
      let updatedData = orders.filter((data) => data._id !== id);
      setOrders(updatedData);
    } catch (error) {
      toast.error("Failed to delete order.");
    }
  };

  const handleEditBtn = (data) => {
    context.changeEditActive("order-edit");
    context.addOrderId(data._id);
    context.addOrderData(data);
    context.changeActive("generateorders");
  };

  const handleView = (data) => {
    setIsViewActive(true);
    setViewData(data);
  };

  function goToNextPage() {
    // console.log(pageNum, "   ", totalPage)
    if (pageNum >= totalPage) {
      return toast.error("No more Pages");
    } else {
      setCurrentPage((page) => page + 1);
      setPageNum(pageNum + 1);
    }
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
    setPageNum((pageNum) => pageNum - 1);
  }

  // function changePage(event) {
  //     const pageNumber = Number(event.target.textContent);
  //     setCurrentPage(pageNumber);
  // }

  const getPaginatedData = () => {
    const startIndex = currentPage * 10 - 10;
    const endIndex = startIndex + 10;
    return orders.slice(startIndex, endIndex);
  };

  // const getPaginationGroup = () => {
  //     let start = Math.floor((currentPage - 1) / 5) * 5;
  //     return new Array(5).fill().map((_, idx) => start + idx + 1);
  // };

  const searchOrder = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `https://api.theeliteqa.com/api/order/search`,
        { searchText: searchText },
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.data !== undefined) {
        setOrders(response.data.data);
        setTotalPage(Math.floor(response.data.data.length / 10) + 1);
        setLoading(false);
        setPages(orders.length / 10);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    if (e.target.value === "") {
      setPageNum(1);
      getOrders();
    } else {
      setSearchText(e.target.value);
    }
  };

  const viewOrderPopUp = () => {
    return (
      <div className="view-client-container">
        <div className="container">
          <div className="heading1">
            <div className="left-side">
              <div className="sidebar"></div>
              <span>View Order</span>
            </div>
            <img
              src={close}
              alt="close"
              onClick={() => {
                setIsViewActive(false);
                setViewData(null);
              }}
            />
          </div>

          <div className="client-data-container">
            <div className="input">
              <span>Client</span>
              <div className="data">
                <span>{viewData.clientId?.companyName}</span>
              </div>
            </div>
            <div className="input">
              <span>Invoice Number</span>
              <div className="data">
                <span>{viewData.invoiceNumber}</span>
              </div>
            </div>
            <div className="input">
              <span>Discount</span>
              <div className="data">
                <span>{viewData.discount}</span>
              </div>
            </div>
            <div className="input">
              <span>Final Amount</span>
              <div className="data">
                <span>{viewData.finalAmount}</span>
              </div>
            </div>
            <div className="input">
              <span>Total Amount</span>
              <div className="data">
                <span>{viewData.totalAmount}</span>
              </div>
            </div>
            <div className="input">
              <span>Package</span>
              <div className="data">
                <span>{viewData.packageId.name}</span>
              </div>
            </div>
            <div className="input">
              <span>Particulars</span>
              <div className="data">
                <span>{viewData.particulars}</span>
              </div>
            </div>
          </div>
          <div className="tan-gst-container">
            <span>
              GSTIN: <span className="gst">{viewData.gst}</span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="list-orders">
      <div className="heading">
        <div className="title-group">
          <div className="sidebar"></div>
          <span>View Orders</span>
        </div>
        <div className="search-container">
          <input
            type="search"
            placeholder="Search here"
            onChange={(e) => {
              handleSearch(e);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                searchOrder();
              }
            }}
          />
          <button
            style={{
              width: "100px",
              height: "40px",
              background: "#384455",
              marginLeft: "20px",
              color: "white",
              cursor: "pointer",
              borderRadius: "2px",
            }}
            onClick={searchOrder}
          >
            Search
          </button>
        </div>
        <div className="pagination">
          {/* previous button */}
          <button
            onClick={goToPreviousPage}
            // className={`prev ${currentPage === 1 ? 'disabled' : ''}`}
            disabled={pageNum <= 1 ? "true" : ""}
            title="Previous"
          >
            <svg
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.41421 6L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289L5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893C7.09763 0.683418 7.09763 1.31658 6.70711 1.70711L2.41421 6Z"
                fill="white"
              />
            </svg>
          </button>

          {/* show page numbers */}
          {/* {getPaginationGroup().map((item, index) => (
                            <button
                                key={index}
                                onClick={changePage}
                                className={`paginationItem ${currentPage === item ? 'active' : null}`}
                            >
                                <span>{item}</span>
                            </button>
                        ))} */}
          <span> {pageNum + "/" + totalPage}</span>

          {/* next button */}
          <button
            onClick={goToNextPage}
            className={`next ${currentPage === pages ? "disabled" : ""}`}
            disabled={pageNum >= totalPage ? "true" : ""}
            title="Next"
          >
            <svg
              width="7"
              height="12"
              viewBox="0 0 7 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
      {loading ? (
        <div className="loading-container">
          <i
            className="fa fa-circle-o-notch fa-spin fa-5x"
            style={{ color: "#FF6813" }}
          ></i>
        </div>
      ) : (
        <div className="staff-container">
          <table>
            <tr>
              <th>Sr.No</th>
              <th>Invoice Number</th>
              <th>Discount</th>
              <th>Final Amount</th>
              <th>Invoice</th>
              <th>Options</th>
            </tr>

            {getPaginatedData()?.map((data, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1 + 10 * (pageNum - 1)}</td>
                  <td>
                    {data.invoiceNumber == null ? "-" : data.invoiceNumber}
                  </td>
                  <td>{data.discount == null ? "-" : data.discount}</td>
                  <td>{data.finalAmount == null ? "-" : data.finalAmount}</td>
                  <td>
                    {" "}
                    <PDFDownloadLink
                      document={<MyDocument data={data} />}
                      fileName={`invoice-${data.invoiceNumber}.pdf`}
                    >
                      {({ blob, url, loading, error }) => (
                        <span>
                          {loading ? "Loading document..." : "Download"}
                        </span>
                      )}
                    </PDFDownloadLink>
                  </td>
                  {/* <td>Download</td> */}
                  <td>
                    <div className="options">
                      <div
                        title="Edit"
                        className="edit"
                        onClick={() => handleEditBtn(data)}
                      >
                        <img src={edit} alt="trash" />
                      </div>

                      <div
                        title="Delete"
                        className="delete"
                        onClick={() => deleteOrder(data._id)}
                      >
                        <img src={trash} alt="trash" />
                      </div>

                      <div
                        title="View"
                        className="view"
                        onClick={() => handleView(data)}
                      >
                        <img src={view} alt="view" />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      )}

      {isViewActive ? viewOrderPopUp() : null}
    </div>
  );
};

export default RightViewOrders;
