import React, { useState, useEffect } from "react";
import "./add_topic.css";
import trash from "../../assets/icons/trash.svg";
import close from "../../assets/icons/close.svg";
import axios from "axios";
import { toast } from "react-toastify";
import edit from "../../assets/icons/edit.svg";
import jwtDecode from "jwt-decode";

const RightAddTopic = () => {
  const [loading, setLoading] = useState(false);
  const [updateLoading, setupdateLoading] = useState(false);
  const [isEditActive, setIsEditActive] = useState(false);
  const [topicId, setTopicId] = useState("");
  const [topics, setTopics] = useState([]);
  const [topicDetails, setTopicDetails] = useState("");
  const [topicLoading, setTopicLoading] = useState(false);
  //for pagination
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNum, setPageNum] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchText, setSearchText] = useState("");

  const token = localStorage.getItem("token");

  const getTopics = async () => {
    try {
      setTopicLoading(true);

      const response = await axios(
        "https://api.theeliteqa.com/api/topic/gettopic",
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.data !== undefined) {
        setTopics(response.data.topics);
        setPages(topics.length / 10);
        setTotalPage(Math.floor(response.data.topics.length / 10) + 1);
        setTopicLoading(false);
      }
    } catch (error) {
      setTopicLoading(false);
    }
  };

  const createTopic = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://api.theeliteqa.com/api/topic/createtopic",
        { topic: topicDetails },
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.status === 201) {
        toast.success("Topic created successfully.");
        setTopicDetails("");
        getTopics();
      }
      setLoading(false);
    } catch (error) {
      if (error.response.data.error.keyValue.topic === topicDetails) {
        toast.error("Topic already exits.");
        setLoading(false);
      } else {
        toast.error("Failed to create topic.");
        setLoading(false);
      }
    }
  };

  const deleteTopic = async (id) => {
    try {
      await axios.delete(
        `https://api.theeliteqa.com/api/topic/deletetopic/${id}`,
        {
          headers: {
            token: token,
          },
        }
      );
      toast.success("Topic deleted successfully.");
      let updatedData = topics.filter((data) => data._id !== id);
      setTopics(updatedData);
    } catch (error) {
      toast.error("Failed to delete topic.");
    }
  };

  const updateTopic = async () => {
    try {
      setupdateLoading(true);
      const res = await axios.patch(
        `https://api.theeliteqa.com/api/topic/update/${topicId}`,
        { topic: topicDetails },
        {
          headers: {
            token: token,
          },
        }
      );
      toast.success("Topic updated successfully.");
      setIsEditActive(false);
      setTopicDetails("");
      let updatedData = topics.map((data) => {
        if (data._id === topicId) {
          return res.data.data;
        } else {
          return data;
        }
      });
      setTopics(updatedData);
      setupdateLoading(false);
    } catch (error) {
      toast.error("Failed to update topic.");
      setupdateLoading(false);
    }
  };

  const handleEdit = (data) => {
    setIsEditActive(true);
    setTopicId(data._id);
    setTopicDetails(data.topic);
  };

  const cancelEdit = () => {
    setIsEditActive(false);
    setTopicId("");
    setTopicDetails("");
  };

  useEffect(() => {
    getTopics();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const validateData = () => {
    if (topicDetails === "") {
      toast.error("Topic cannot be empty");
    } else {
      createTopic();
    }
  };

  function goToNextPage() {
    // console.log(pageNum, "   ", totalPage)
    if (pageNum >= totalPage) {
      return toast.error("No more Pages");
    } else {
      setCurrentPage((page) => page + 1);
      setPageNum(pageNum + 1);
    }
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
    setPageNum((pageNum) => pageNum - 1);
  }

  // function changePage(event) {
  //     const pageNumber = Number(event.target.textContent);
  //     setCurrentPage(pageNumber);
  // }

  const getPaginatedData = () => {
    const startIndex = currentPage * 10 - 10;
    const endIndex = startIndex + 10;
    return topics.slice(startIndex, endIndex);
  };

  // const getPaginationGroup = () => {
  //     let start = Math.floor((currentPage - 1) / 5) * 5;
  //     return new Array(5).fill().map((_, idx) => start + idx + 1);
  // };

  const searchTopic = async () => {
    try {
      setTopicLoading(true);
      const response = await axios.get(
        `https://api.theeliteqa.com/api/topic/search?searchText=${searchText}`,
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.data !== undefined) {
        setTopics(response.data.data);
        setPages(topics.length / 10);
        setTotalPage(Math.floor(response.data.data.length / 10) + 1);
        setTopicLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    if (e.target.value === "") {
      setPageNum(1);
      getTopics();
    } else {
      setSearchText(e.target.value);
    }
  };

  const editPopUp = () => {
    return (
      <div className="edit-container">
        <div className="container">
          <div className="heading">
            <div className="left-side">
              <div className="sidebar"></div>
              <span>Edit Topic</span>
            </div>
            <div className="btn-container">
              <button onClick={updateTopic}>
                {updateLoading ? "Loading..." : "Update"}
              </button>
              <img src={close} alt="close" onClick={cancelEdit} />
            </div>
          </div>
          <div className="form">
            <span>Selected Topic</span>
            <input
              type="text"
              placeholder="Mern Stack"
              value={topicDetails}
              onChange={(e) => {
                setTopicDetails(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="add-topic-container">
      <div className="add-topic-form-container">
        <div className="heading">
          <div className="sidebar"></div>
          <span>Add Topics</span>
        </div>
        <div className="form">
          <input
            type="text"
            placeholder="Topic Here"
            onChange={(e) => setTopicDetails(e.target.value)}
            value={isEditActive ? "" : topicDetails}
          />
          <button onClick={validateData} title="Add Topic">
            {loading ? <i class="fa fa-circle-o-notch fa-spin"></i> : "+"}
          </button>
        </div>
      </div>

      <div className="list-topic">
        <div className="heading">
          <div className="title-group">
            <div className="sidebar"></div>
            <span>Added Topics</span>
          </div>
          <div className="search-container">
            <input
              type="search"
              placeholder="Search here"
              onChange={(e) => {
                handleSearch(e);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  searchTopic();
                }
              }}
            />
            <button
              style={{
                width: "100px",
                height: "40px",
                background: "#384455",
                marginLeft: "20px",
                color: "white",
                cursor: "pointer",
                borderRadius: "2px",
              }}
              onClick={searchTopic}
            >
              Search
            </button>
          </div>
          <div className="pagination">
            {/* previous button */}
            <button
              onClick={goToPreviousPage}
              // className={`prev ${currentPage === 1 ? 'disabled' : ''}`}
              disabled={pageNum <= 1 ? "true" : ""}
              title="Previous"
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.41421 6L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071L0.292893 6.70711C-0.0976311 6.31658 -0.0976311 5.68342 0.292893 5.29289L5.29289 0.292893C5.68342 -0.0976311 6.31658 -0.0976311 6.70711 0.292893C7.09763 0.683418 7.09763 1.31658 6.70711 1.70711L2.41421 6Z"
                  fill="white"
                />
              </svg>
            </button>

            {/* show page numbers */}
            {/* {getPaginationGroup().map((item, index) => (
                            <button
                                key={index}
                                onClick={changePage}
                                className={`paginationItem ${currentPage === item ? 'active' : null}`}
                            >
                                <span>{item}</span>
                            </button>
                        ))} */}
            <span> {pageNum + "/" + totalPage}</span>

            {/* next button */}
            <button
              onClick={goToNextPage}
              className={`next ${currentPage === pages ? "disabled" : ""}`}
              disabled={pageNum >= totalPage ? "true" : ""}
              title="Next"
            >
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683418 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
        {topicLoading ? (
          <div className="loading-container">
            <i
              class="fa fa-circle-o-notch fa-spin fa-5x"
              style={{ color: "#FF6813" }}
            ></i>
          </div>
        ) : (
          <div className="topic-container">
            <table>
              <tr>
                <th>Sr.No</th>
                <th>Topic</th>
                <th>Options</th>
              </tr>

              {getPaginatedData().map((data, index) => {
                return (
                  <tr>
                    <td>{index + 1 + 10 * (pageNum - 1)}</td>
                    <td>{data.topic}</td>
                    <td>
                      <div className="options">
                        <div
                          title="Edit"
                          className="edit"
                          onClick={() => handleEdit(data)}
                        >
                          <img src={edit} alt="trash" />
                        </div>

                        <div
                          title="Delete"
                          className="delete"
                          onClick={() => deleteTopic(data._id)}
                        >
                          <img src={trash} alt="trash" />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        )}
      </div>

      {isEditActive ? editPopUp() : null}
    </div>
  );
};

export default RightAddTopic;
